import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/_causetagmobile.css';
import SLLink from './SLLink';

export default function CauseTagMobile (props) {

  const causeLink = `/biz/${props.business.businessId}/support/${props.cause.causeId}`;

  return (
    <div className="cause-tag-mobile">
      <div className="cause-tag-mobile-support-text">
        <div className="cause-tag-mobile-promise-goal">{`Every $${props.cause.promiseGoal}`}</div>
        <div className="cause-tag-mobile-promise">{`We ${props.cause.promise}`}</div>
      </div>
      <div className="cause-tag-mobile-support-button-container">
        <SLLink 
          trackingInfo={{name: "VIEW_CAUSE", info : {cause: causeLink}}}
          className="cause-tag-mobile-support-link" 
          to={causeLink}>
          <button className="cause-tag-mobile-support-button">Support Now</button>
        </SLLink>
      </div>
    </div>
  );
}
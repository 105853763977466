import {
    LOADING_UI, 
    SET_ERRORS,
    CLEAR_ERRORS,
    CREATE_BUSINESS,
    SET_BUSINESS,
    STOP_LOADING_UI,
    RESET_BUSINESS,
    LIKE_BUSINESS,
    UNLIKE_BUSINESS,
    SET_CAUSE,
    CREATE_CAUSE,
    EDIT_CAUSE,
    DELETE_CAUSE,
    LOADING_UI_ELEMENT,
    STOP_LOADING_UI_ELEMENT,
    STOP_LOADING_UI_ELEMENTS,
    SET_FEATURED_BUSINESSES,
} from '../types';

import axios from 'axios';

export const addNewBusiness = (newBusiness) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    axios
    .post('/createBusiness', newBusiness)
    .then((res) => {
        dispatch({
            type: CREATE_BUSINESS,
            payload: res.data
        });
        dispatch(clearErrors());
    })
    .catch((err) => {
        dispatch({
            type: SET_ERRORS, 
            payload: err.response
        });
    });
};

export const getBusiness = (businessId) => (dispatch) => {
    dispatch({type: LOADING_UI});
    dispatch({
        type: LOADING_UI_ELEMENT,
        payload: 'business',
    })

    axios.get(`/businesses/${businessId}`)
    .then((res) => {
        dispatch({
            type: SET_BUSINESS,
            payload: res.data,
        });
        dispatch({
            type: CLEAR_ERRORS
        })
        dispatch({ 
            type: STOP_LOADING_UI,
            payload: res.data,
         });
        dispatch({ 
            type: STOP_LOADING_UI_ELEMENT,
            payload: 'business',
         });
    })
    .catch((err) => {
        console.log("Error:", err)
        dispatch({
            type: SET_ERRORS,
            payload: err.response,
        });
    });
}

export const likeBusiness = (businessId) => (dispatch) => {
    axios.get(`/businesses/${businessId}/like`)
    .then(res => {
        dispatch({
            type: LIKE_BUSINESS,
            payload: res.data,
        })
    })
    .catch((err) => 
        dispatch({
            type: SET_ERRORS,
            payload: err.response,
        })
    );
}

export const unlikeBusiness = (businessId) => (dispatch) => {
    axios.get(`/businesses/${businessId}/unlike`)
    .then(res => {
        dispatch({
            type: UNLIKE_BUSINESS,
            payload: res.data,
        })
    })
    .catch((err) => {
        dispatch({
            type: SET_ERRORS,
            payload: err.response,
        })
    });
}

export const createCause = (causeDetails) => (dispatch) => {
    dispatch({type: LOADING_UI});
    axios.post(`/causes/create`, causeDetails)
    .then(res => {
        dispatch({
            type: CREATE_CAUSE,
            payload: res.data
        })
        dispatch({type: STOP_LOADING_UI});
    })
    .catch(err => {
        dispatch({
            type: SET_ERRORS,
            payload: err.response
        })
    })
}

export const getCause = (causeId) => (dispatch) => {
    dispatch({
        type: LOADING_UI_ELEMENT,
        payload: 'cause',
    })
    axios.get(`/causes/${causeId}`)
    .then(res => {
        dispatch({
            type: SET_CAUSE,
            payload: res.data,
        });
        dispatch({
            type: STOP_LOADING_UI_ELEMENT,
            payload: 'cause',
        });
    })
    .catch((err) => {
        dispatch({
            type: SET_ERRORS,
            payload: err.response,
        })
    })
}

export const editCause = (causeDetails) => (dispatch) => {
    axios.post(`/causes/${causeDetails.causeId}`)
    .then(res => {
        dispatch({
            type: EDIT_CAUSE,
            payload: res.data
        })
    })
    .catch((err) => {
        dispatch({
            type: SET_ERRORS,
            payload: err.response,
        })
    })
}

export const deleteCause = (causeId) => (dispatch) => {
    dispatch({type: LOADING_UI});

    console.log("Deleting Cause", causeId);

    axios
    .delete(`/causes/${causeId}`)
    .then(res => {
        dispatch({
            type: DELETE_CAUSE,
            payload: causeId
        })

        dispatch({type: STOP_LOADING_UI});
    })
    .catch((err) => {
        dispatch({
            type: SET_ERRORS,
            payload: err.response
        })
    });
}

export const getFeaturedBusinesses = () => (dispatch) => {
    dispatch({
        type: LOADING_UI_ELEMENT, 
        payload: 'featuredBusinesses'
    });

    console.log("Getting features");

    axios.get(`/businesses/featured/getFeatured`)
    .then((res) => {
        dispatch({
            type: SET_FEATURED_BUSINESSES,
            payload: res.data,
        })
        dispatch({
            type: STOP_LOADING_UI_ELEMENT,
            payload: 'featuredBusinesses'
        })
    })
    .catch((err) => {
        dispatch({
            type: SET_ERRORS,
            payload: err.response,
        });
    });
}

// export const getBusinesses = () => (dispatch) => {
//     dispatch({
//         type: LOADING_UI_ELEMENT,
//         payload: 'businesses',
//     });

//     axios.get(`/businesses/${businessId}`)
//     .then((res) => {
//         dispatch({
//             type: SET_BUSINESS,
//             payload: res.data,
//         })
//         dispatch({ 
//             type: STOP_LOADING_UI,
//             payload: res.data,
//          })
//         dispatch({ 
//             type: STOP_LOADING_UI_ELEMENT,
//             payload: 'business',
//          })
//     })
//     .catch((err) => {
//         console.log("Error:", err)
//         dispatch({
//             type: SET_ERRORS,
//             payload: err.response,
//         });
//     });
// }

export const resetBusiness = () => (dispatch) => {
    dispatch({
        type: RESET_BUSINESS,
    })
}

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS })
}


import React from 'react';

import '../styles/_specialbutton.css';

var classNames = require('classnames');

class SpecialButton extends React.Component {

  render () {
    return (
      <button 
        {...this.props} 
        className={classNames("special-button", this.props.className)} >
        {this.props.children}
      </button>
    )
  }
}

export default SpecialButton;
import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import '../styles/_loadingbutton.css';

var classNames = require('classnames');

export default function LoadingButton (props) {

  function trackClick (e) {
    props.onClick && props.onClick(e);
    props.trackingInfo && window.analytics.track("ACTION:" + props.trackingInfo.name, props.trackingInfo.info);
  }

  return (
    <button 
    {...props} onClick={trackClick} className={classNames("loading-button", props.className)}>
      {
        props.loading ? 
          <PulseLoader /> :
          props.text
      }
    </button>
  )
}
import React from 'react';

import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import '../styles/_loadingpage.css';

export default function LoadingPage () {
  return (
    <div className="loading-page">
      <ClimbingBoxLoader size={24} />
      <span className="loading-page-text">Loading...</span>
    </div>
  )
}
import React from 'react';
import '../styles/_signin.css';
import '../styles/_login.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SLButton from '../components/SLButton';

// Redux 
import { connect } from 'react-redux';
import { signupUser } from '../redux/actions/userActions';
import { Redirect } from 'react-router-dom';

import heroIllustration from '../resources/heroIllustration.svg';
import LoadingPage from '../components/LoadingPage';
import SLLink from '../components/SLLink';

let classNames = require('classnames');

const logInValidation = Yup.object().shape({
  email: Yup.string()
    .required('No email provided')
    .email('Must be a valid email.'),
  password: Yup.string()
    .required('No password provided')
    .min(8, 'Password must be at least 8 characters.')
    .max(50, 'Password cannot be longer than 50 characters'), 
  handle: Yup.string()
  .required('No username provided')
  .matches(/[a-zA-Z]/, 'Can only contain the letters A-Z'),
  terms: Yup.bool()
  .required()
  .oneOf([true], "You must agree to the terms and conditions and privacy policy."),
})


class SignUp extends React.Component {
  state = {
    loading: false,
    errors: {},
  }

  defaultValues = {
    email: '',
    handle: '',
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ 
        errors: nextProps.UI.errors.data || {},
        loading: nextProps.UI.loading,
      })
    }
  }

  handleSignup = (newUserData) => {
    this.props.signupUser(newUserData, this.props.history);
  }

  renderForm = () => {
    const { UI } = this.props;
    const labelClassName = classNames("sign-in-form-label", {"mobile": this.props.isMobile});
    const inputClassName = classNames("sign-in-form-input", {"mobile": this.props.isMobile});
    const errorClassName = classNames("sign-in-form-error", {"mobile": this.props.isMobile});

    return (
      <Formik
        initialValues={this.defaultValues}
        validationSchema={logInValidation}
        onSubmit={(values, actions) => {
            this.handleSignup(values);
            actions.setSubmitting(false);
        }}>
        <Form className={classNames("sign-in-form", {"mobile": this.props.isMobile})}>
            <label className={labelClassName} htmlFor="handle">Username</label>
            <Field className={inputClassName} type="text" name="handle" />
            <ErrorMessage className={errorClassName} name="handle" component="div" />
            <label className={errorClassName} name="handle">{this.state.errors.handle}</label>

            <label className={labelClassName} htmlFor="email">Email</label>
            <Field className={inputClassName} type="text" name="email" />
            <ErrorMessage className={errorClassName} name="email" component="div" />
            <label className={errorClassName} name="handle">{this.state.errors.email}</label>

            <label className={labelClassName} htmlFor="password">Password</label>
            <Field className={inputClassName} type="password" name="password" />
            <ErrorMessage className={errorClassName} name="password" component="div" />
            <label className={errorClassName} name="handle">{this.state.errors.password}</label>
            
            <label htmlFor="terms" className={labelClassName + " sign-in-terms-label"}>
            <Field type="checkbox" className="sign-in-checkbox" name="terms" />
            <span className="sign-in-checkbox-spacer" />I have read and agree to the <SLLink trackingInfo={{name:"VIEW_TERMS_ON_SIGNUP"}} className="sign-in-terms-link" to='/terms'>Terms and Conditions</SLLink> and <SLLink trackingInfo={{name:"VIEW_PRIVACY_POLICY_ON_SIGNUP"}} className="sign-in-terms-link" to='/privacy'>Privacy Policy.</SLLink></label>
            <ErrorMessage name="terms" component="div" className={errorClassName} /> 

            <div className={errorClassName} component="div">{this.state.errors && this.state.errors.general}</div>

            <SLButton
              trackingInfo={{name: "USER_SIGNUP"}}
              type="submit" 
              className={classNames("sign-in-submit-button", {"mobile":this.props.isMobile})} 
              loading={this.state.loading || UI.elementsLoading.signup}>Sign Up</SLButton>

            <div className="sign-in-submit-separator">
                Or
            </div>

            <SLLink
              to="/login"
              trackingInfo={{name: "CONVERT_TO_LOGIN"}}
              className={classNames(
                "sign-in-other-button", 
                "sign-in-submit-button", 
                {"mobile":this.props.isMobile})}>Log In</SLLink>
        </Form>
    </Formik>
    );
  }

  renderMobile = () => {
    return (
      <div className="sign-in-body-mobile">

      <div className="sign-in-form-wrapper mobile">
        <div className="sign-in-form-title mobile">
            Sign Up
        </div>
        {this.renderForm()}
    </div>
      </div>
    )
  }

  renderDesktop = () => {
    const { user } = this.props;
    const { authenticated } = user;

    return (
        <div className="sign-in-body">
            <div className="sign-in-info">
                <div className="sign-in-info-text">
                    <div className="sign-in-info-title">
                        Buy Local, Build Local
                    </div>
                    <div className="sign-in-info-subtitle">
                        Support local businesses while getting awesome deals!
                    </div>
                </div>
                <img 
                className="sign-in-hero-illustration" 
                alt="an illustration of small business owners in front of stores and shops" src={heroIllustration} />
            </div>
            <div className="sign-in-form-container">
                <div className="sign-in-form-wrapper">
                    <div className="sign-in-form-title">
                        Sign Up
                    </div>
                    <div className="sign-in-form-subtitle">
                        Let's Strengthen This Community, Together!
                    </div>
                    {this.renderForm()}
                </div>
            </div>
        </div>
    )
  }

  render () {
    const { user, UI } = this.props;
    const { authenticated } = user;

    if (authenticated) {
      return <Redirect to='/businesses' />
    } 
    
    if (UI.elementsLoading.user) {
      return <LoadingPage />
    }

    if (this.props.isMobile) {
      return this.renderMobile();
    } else {
      return this.renderDesktop();
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
})

const mapActionsToProps = ({
  signupUser
})

export default connect(mapStateToProps, mapActionsToProps)(SignUp);

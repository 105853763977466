import React from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import SpecialButton from '../components/SpecialButton';

import { businessCategories } from '../util/data.js';

// Redux
import { connect } from 'react-redux';
import { addNewBusiness } from '../redux/actions/dataActions';

// Formik
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Styles 
import '../styles/_addnewbusinessmodal.css';
import SLButton from '../components/SLButton';


const userProfileValidation = Yup.object().shape({
    name: Yup.string()
    .required("Must add Business Name")  
    .max(200, 'Must be under 200 characters.'),
    address: Yup.string()
    .required("Must add Business Address")  
    .max(200, 'Must be under 200 characters.'),
    zipCode: Yup.string()
    .required("Business zip code is required.")
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Must be a valid 5 or 9 digit zip code."),
    category: Yup.string()
    .required("You must choose a business category."),
    ownsBusiness: Yup.bool()
    .oneOf([true], "You must certify that you're authorized to display this business!"),
    secondaryCategory: Yup.string()
    .when('category', {
      is: 'Other',
      then: Yup.string().required("Must add a custom business category!").min(4, "Must be at least 4 characters").max(25, "Can't be more than 25 characters"), 
    }),
  })

let initialValues = {
  name: '',
  address: '', 
  zipCode: '',
  category: businessCategories[0],
  secondaryCategory: '',
  ownsBusiness: false,
}


class ManageProfileModal extends React.Component {
    state = {
        errors: {},
        newImage: null,
        newImageSrc: null,
        zipCode: "",
        showOther: false,
    }

    cancelAddBusiness = () => {
        this.props.toggle && this.props.toggle();
    }

    handleAddNewBusiness = (newBusinessDetails) => {
        this.props.addNewBusiness(newBusinessDetails);
        this.props.toggle && this.props.toggle();
    }

    checkcategory = () => {
      let input = document.getElementById('add-new-business-category-input');
      if (input && input.value === 'Other') {
        this.setState({
          showOther: "true"
        })
      } else {
        this.setState({
          showOther: "false"
        })
      }
    }

    render () {
      return (
        <Modal 
          isOpen={this.props.isOpen} 
          toggle={this.props.toggleModal}
          className={"add-new-business-modal"}>
          <ModalHeader>Add a New Business <h6>Start receiving support for your business on SpendLocal!</h6></ModalHeader>
          <ModalBody className="add-new-business-modal-body">
            <Formik
                initialValues={initialValues}
                validationSchema={userProfileValidation}
                onSubmit={(values, actions) => {
                    this.handleAddNewBusiness(values);
                    actions.setSubmitting(false);
                }}
                enableReinitialize>
                  {({values}) => (
                    <Form className="add-new-business-modal-form">
                      <div className="add-new-business-row">
                          <label className="add-new-business-label" htmlFor="name">Business Name</label>
                          <Field placeholder="Jack's Tavern" className="add-new-business-input" type="text" name="name" />
                          <ErrorMessage className="add-new-business-error" name="name" component="div" />
                      </div>

                      <div className="add-new-business-row">
                          <label className="add-new-business-label" htmlFor="address">Address</label>
                          <Field placeholder="123 Jameson St. Jakers, NY" className="add-new-business-input" type="text" name="address" />
                          <ErrorMessage className="add-new-business-error" name="address" component="div" />
                      </div>        

                      <div className="add-new-business-row">
                          <label className="add-new-business-label" htmlFor="zipCode">Zip Code</label>
                          <Field placeholder="12345" className="add-new-business-input add-new-business-zip-code-input" type="text" name="zipCode" />
                          <ErrorMessage className="add-new-business-error" name="zipCode" component="div" />
                      </div>        

                      <div className="add-new-business-row">
                          <label className="add-new-business-label" htmlFor="category">Category</label>
                          <Field id="add-new-business-category-field" className="add-new-business-input add-new-business-category-input" as="select" name="category">
                            {businessCategories.map((category) => {
                              return <option value={category}>{category}</option>
                            })}
                          </Field>
                          <ErrorMessage className="add-new-business-error" name="category" component="div" />
                          {
                            values.category === "Other" && 
                            <Field placeholder="Custom Category" className="add-new-business-input add-new-business-category-input" type="text" name="secondaryCategory" />
                          }
                          <ErrorMessage className="add-new-business-error" name="secondaryCategory" component="div" />
                      </div>

                      <div className="add-new-business-checkbox-row">
                          <label 
                            htmlFor="ownsBusiness" 
                            className="add-new-business-checkbox-label">
                          <Field 
                            type="checkbox" 
                            className="add-new-business-checkbox" 
                            name="ownsBusiness" />
                            <span className="add-new-checkbox-spacer" />I own this business or I am authorized by the owner of this business to post this business profile.
                          </label>
                        <ErrorMessage 
                        name="ownsBusiness" 
                        component="div" 
                        className="add-new-business-error" /> 
                      </div>


                      <div className="manage-profile-footer">
                        <SLButton
                          trackingInfo={{name: "CANCEL_ADD_BUSINESS"}}
                          className="spendlocal-button-container spendlocal-cancel-button"
                          type="reset"
                          onClick={this.cancelAddBusiness}>Cancel</SLButton>
                        <SLButton
                          trackingInfo={{name: "SUBMIT_ADD_BUSINESS"}}
                          className="spendlocal-button-container spendlocal-accept-button"
                          type="submit">Add Business</SLButton>
                      </div> 
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      )
  }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
    data: state.data,
})

export default connect(
    mapStateToProps, 
    { addNewBusiness }
)(ManageProfileModal);
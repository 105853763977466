import React, { useState } from 'react';

import PageHeader from '../components/PageHeader';

import dummyBusinesses from '../util/defaultDetail';
import BusinessCarousel from '../components/BusinessCarousel';
import BusinessMarker from '../components/BusinessMarker';

function Favorites() {
  const layers = [2, 3, 1];
  return (
    <div className="page-body">
      <PageHeader mainTitle="Your Favorite Spots" subTitle="See local businesses you've favorited." />
      {
        Object.keys(dummyBusinesses).map((category, ci) => {
          return (
            <BusinessCarousel title={category}>
              {dummyBusinesses[category].map((business, bi) => {
                if (bi < layers[ci]) {
                  return ( 
                    <BusinessMarker
                      deal={business.deal}
                      businessId={business.businessId}
                      businessImage={business.thumbnailImage}
                      businessName={business.name}
                      shortDescription={business.shortDescription}
                      liked={business.liked} />
                    )
                }
              })}
            </BusinessCarousel>
          )
        })
      }
    </div>
  );
}

export default Favorites;

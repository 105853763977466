import React from 'react';

function Organizations() {
  return (
    <div className="App">
      <h1>Organizations Page</h1>
    </div>
  );
}

export default Organizations;

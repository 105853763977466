import React from 'react';
import SLLink from '../components/SLLink'; 

import '../styles/_doesnotexist.css';

export default function DoesNotExist () {
  return (
    <div className="page-body">
      <div className="not-exist-body">
        404: This page does not exist!

        <div>
          <div className="not-exist-cta">Find other businesses on SpendLocal</div>
          <SLLink
            className="not-exist-cta-button" 
            to='/businesses'
            trackingInfo={{name: "FIND_BUSINESSES_AFTER_PAYMENT"}}>
            Find New Businesses
          </SLLink>
        </div>


      </div>
    </div>
  )
}
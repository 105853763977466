import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import LoadingPage from '../components/LoadingPage';

import CauseTag from '../components/CauseTag';
import CheckoutForm from '../components/CheckoutForm';

import PageHeader from '../components/PageHeader';
import '../styles/_localbusinesschoosesupport.css';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getBusiness, getCause } from '../redux/actions/dataActions';
import PaymentComplete from '../components/PaymentComplete';

const stripePromise = loadStripe('pk_live_2Vzlk2y5bJWG785Rxl5waImV00XU7BBPys');  // Live
// const stripePromise = loadStripe('pk_test_51GtdTzJsplIySomsXqsLP2tfYcqd8X0FHJDv5GBZW5XG3fyXk61KvQwiF38W46ydEmxyu3XJgoOlt2ylTbEC670g00SsquEr26'); // Test

export default function LocalBusinessChooseSupport () {

  let [paymentStatus, setPaymentStatus] = useState('incomplete'); // incomplete, failed, success
  let [paymentError, setPaymentError] = useState(null);
  let [checkoutAmount, setCheckoutAmount] = useState(20);
  let [checkoutEmail, setCheckoutEmail] = useState("");

  // Redux state
  const { business, cause } = useSelector(state => state.data);
  const UI = useSelector(state => state.UI);

  const { businessId, causeId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    let businessEmpty = Object.keys(business).length === 0;
    let causeEmpty = Object.keys(cause).length === 0;

    if ((businessEmpty || business.businessId !== businessId) &&
      !UI.elementsLoading.business)
    {
      dispatch(getBusiness(businessId));
    }

    if (!businessEmpty) {
      if (
      (business.featuredCauseId !== "") && 
      (business.featuredCauseId !== cause.causeId) &&
      (!UI.elementsLoading.cause)) {
        dispatch(getCause(business.featuredCauseId));
      }
    }
  });

  if (UI.elementsLoading.cause || UI.elementsLoading.business) {
    return <LoadingPage />
  }

  function renderPaymentScreen () {
    return (
      <div className="local-business-support-items">
        <div className="local-business-support-cause-tag">
          <CauseTag className="solo-tag" cause={cause} />
        </div> 
        <Elements stripe={stripePromise}>
          <CheckoutForm 
            cause={cause} 
            business={business} 
            setCheckoutAmount={setCheckoutAmount}
            setCheckoutEmail={setCheckoutEmail}
            setPaymentStatus={setPaymentStatus} 
            setPaymentError={setPaymentError} />
        </Elements>
      </div>
    )
  }

  return (
    <div className="page-body"> 
      <div className="local-business-support-page">
        <PageHeader 
          mainTitle={`Support ${business.name}`} 
          subTitle={cause.title}>
        </PageHeader> 
        {
          paymentStatus === 'incomplete' ? 
          renderPaymentScreen() : 
          <PaymentComplete 
            amount={checkoutAmount}
            cause={cause.title}
            email={checkoutEmail}
            paymentError={paymentError} 
            paymentStatus={paymentStatus} />
        }

      </div>
    </div>
  );
}
import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/_sllink.css';
const classNames = require('classnames');

export default function SLLink (props) {
  
  const trackClick = (e) => {
    props.onClick && props.onClick(e);
    props.trackingInfo && window.analytics.track(props.trackingInfo.name, props.trackingInfo.info);
  }

  return (
    <Link {...props} className={classNames("sl-link", props.className)} onClick={trackClick}>{props.children}</Link>
  );
}
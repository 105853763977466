import React from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';

import '../styles/_favoritebutton.css';
import SLButton from './SLButton';

var classNames = require('classnames');

export default function FavoriteButton (props) {
  let liked = props.liked;
  let favorite = props.favorite || (() => {});
  let unfavorite = props.unfavorite || (() => {});

  const actionName = liked ? "UNFAVORITE_BUSINESS" : "FAVORITE_BUSINESS";
  return (
    <SLButton
      trackingInfo={{name: actionName}}
      onClick={liked ? unfavorite : favorite}
      className={
        classNames(
          "favorite-button-container", 
          "selectable", 
          "dark-shadow", 
          {"favorite-button-container-liked": liked}
        )
      }>
      {
        liked ? 
        <FavoriteIcon className="favorite-icon liked" /> :
        <FavoriteBorderOutlinedIcon className="favorite-icon" />
      }

      {
        !props.noText &&
        <span className="favorite-button-text">{liked ? "Favorited" : "Favorite this business"}</span>
      }
    </SLButton>
  );
}
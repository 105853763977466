import React from 'react';

import { useSelector } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import '../styles/_paymentcomplete.css';
import SLButton from './SLButton';
import SLLink from './SLLink';

export default function PaymentComplete (props) {

    let { authenticated } = useSelector(state => state.user);

    const renderPaymentSuccess = () => {
      return (
        <div>
          <h2>Payment Complete!</h2>
          <CheckCircleIcon className="success-icon" fontSize="large"/>
          <div className="payment-complete">{`You're support of $${props.amount} has been processed!`}</div>
          <div>{`A receipt as been emailed to ${props.email}`}</div> 
          <hr />
          { authenticated && renderAuthenticatedDetails() }
          { !authenticated && renderUnauthenticatedDetails() }
        </div>
      );
    }

    const renderPaymentFailed = () => {
      return (
        <div>
          <h2 className="header-failed">Payment Failed</h2>
          <CancelIcon className="fail-icon" fontSize="large"/>
          <SLButton 
            trackingInfo={{name: "RETRY_PAYMENT"}}
            className="call-to-action-button-container"
            onClick={() => window.location.reload(true)}>
            Retry
          </SLButton>
        </div>
      )
    }

    const renderAuthenticatedDetails = () => {
      return (
        <div>
          <div>Find new businesses to support here!</div>
          <SLLink
            className="call-to-action-button-container" 
            to='/businesses'
            trackingInfo={{name: "FIND_BUSINESSES_AFTER_PAYMENT"}}>
            Find New Businesses
          </SLLink>
        </div>
      )
    }

    const renderUnauthenticatedDetails = () => {
      return (
        <div>
          <div>Create a SpendLocal account to track support, find new businesses, and get great deals!</div>
          <SLLink
            className="call-to-action-button-container" 
            to='/signup'
            trackingInfo={{name: "SIGNUP_AFTER_PAYMENT"}}>
            Create Account
          </SLLink>
        </div>
      )
    }

    return (
        <div class="page-body payment-complete-page" >
            {!props.paymentError && renderPaymentSuccess()}
            {props.paymentError && renderPaymentFailed()}
        </div>
    )
}
import React from 'react';

import '../styles/_causetag.css';

// Redux
import { connect } from "react-redux";
import { editCause, deleteCause } from "../redux/actions/dataActions";
import { editBusinessDetails } from "../redux/actions/userActions";
import SLButton from './SLButton';

let classNames = require('classnames');


class CauseTag extends React.Component {

  renderCauseTag = () => {
    let cause = this.props.cause || {};
    return (
      <div className="cause-tag">
        {
          this.props.featured && 
          <div className="cause-tag-featured-option">
            Featured
          </div>
        }
      
        <div className="cause-tag-accent-title">
          Support this Cause!
        </div>
        <div className="cause-tag-inner-container">

          <div className="cause-tag-title">
            {cause.title}
          </div>

          <div className="cause-tag-promise-container">
            <div className='cause-tag-promise-title'>
              {`Every $${cause.promiseGoal}`}
            </div>
            <hr/>
            <div className='cause-tag-promise-body'>
              {`We ${cause.promise || "____"}`}
            </div>
          </div>

          {
            cause.benefit !== "" &&
            <div className="cause-tag-promise-container">
              <div className='cause-tag-promise-title'>
                {`Pledge at least $${cause.benefitMinimum}`}
              </div>
              <hr/>
              <div className='cause-tag-promise-body'>
                {`You get ${cause.benefit || "____"}*`}
              </div>
            </div>
          }

          {
            cause.benefit &&
            <div className="cause-tag-terms">
              {`*${cause.benefitTerms}`}
            </div>
          }

          {
            !this.props.featured &&
            this.props.isAdmin &&
              <SLButton
                trackingInfo={{name: "FEATURE_CAUSE", info: {causeId: cause.causeId}}}
                className="cause-tag-feature-button"
                onClick={() => {this.props.editBusinessDetails({featuredCauseId: cause.causeId, businessId: this.props.businessId})}} >
                Feature this Cause!
              </SLButton>
          }
        </div>
      </div>
    )
  }


  render () {
    return (
      <SLButton
        trackingInfo={{name: "VIEW_CAUSE"}}
        className={classNames("cause-tag-container", this.props.className)}>
        {this.renderCauseTag()}
      </SLButton>
    );
  }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
  });
  
export default connect(mapStateToProps, { editCause, deleteCause, editBusinessDetails })(CauseTag);

import React from 'react';

import '../styles/_formtabs.css';
import SLButton from './SLButton';

let classNames = require("classnames");

class FormTabs extends React.Component {
  state = {
    tabIndex: 0,
  }

  renderTab = (tab, index) => {
    return (
      <SLButton
        trackingInfo={{name: "CHOOSE_TAB", info: {tab}}}
        key={index} 
        onClick={() => {this.setState({tabIndex: index})}} 
        className={classNames("form-tab", {"form-tab-selected": this.state.tabIndex === index})}>
          {tab}
      </SLButton>
    )
  }

  setTab = (tab) => {
    this.setState({tabIndex: tab});
  }

	render () {

    return (
      <div className="form-tabs-container">
        <div className="form-tabs-header">
         {
           this.props.tabs.map(this.renderTab)
         }
        </div>
        <div className="form-tabs-body">
          {this.props.children && React.Children.toArray(this.props.children)[this.state.tabIndex]}
        </div>
      </div>
    ) 

	}
}

export default FormTabs;
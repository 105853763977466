import React from 'react';
import '../styles/_businessmarker.css';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BarLoader from 'react-spinners/BarLoader';
import SLLink from './SLLink';

var classNames = require('classnames');

export default function BusinessMarker (props) {

  const businessLink = props.business ? '/biz/' + props.business.businessId : '/biz/';
  if (props.business) {
    return (
      <SLLink
      trackingInfo={{name: "VIEW_BUSINESS", info: { businessLink }}}
      className="site-link" 
      to={ businessLink }>
        <div className={classNames("business-marker-container", props.className)}>
          { 
            props.business.deal && 
            <div className="business-marker-deal">
              {props.business.deal}
            </div>
          }

          {
            props.liked &&
            <div className="business-marker-liked">
              <FavoriteIcon className="business-marker-liked-icon" />
            </div>
          }

          <div className="business-marker-image-container">
            <img className="business-marker-image" alt="business thumbnail" src={props.business.thumbnailImage}/>
          </div>
          <div className="business-marker-info">
            <div className="business-marker-title">{props.business.name}</div>
          </div>
        </div> 
      </SLLink>
    );     
  }

  return (
    <div className={classNames("business-marker-container", props.className)}>
      <div className="business-marker-image-container loading">
        {props.loading && <BarLoader size={30} color={'#4C4C4C'}/> }
      </div>
      <div className="business-marker-info loading">
        <div className="business-marker-title loading">
        </div>
      </div>
    </div>
  );
}
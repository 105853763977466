const dummyBusinesses = {
    "Restaurants": [
      {
        thumbnailImage: "https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80",
        coverImage: "https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80",
        businessId: "1234-5678-9101-1234",
        name: "Jack's Cooler", 
        nickname: "Jack's",
        shortDescription: "Cold Shells and Ice",
        deal: "20%",
        yearStarted: 1993,
        website: "www.jackscoolerny.com",
        phone: "12345678901", 
        address: "112 Jameson St. Commack, NY",
        numEmployees: 14,
        longDescription: "Jack's has been a mainstay in Commack for over 20 years. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea.", 
        tags: ["beer", "drinks", "fun", "restaurant", "grill"],
        category: "Restaurants",
        zipCode: "12345",
        offers: [
          {
            title: "Free Breadsticks",
            cost: 2,
            shortDescription: "Free Breadsticks with Large Pizza!",
            longDescription: "Receive 1 free order of breadsticks with any large pizza.",
            cadence: "every month",
            offerId: "123456",
          },
          {
            title: "5%",
            cost: 5,
            shortDescription: "5% off all orders!",
            longDescription: "Receive 5% Off all orders with your offer code.",
            cadence: "anytime",
            offerId: "234567",
          },
          {
            title: "10%",
            cost: 10,
            shortDescription: "10% off all orders!",
            longDescription: "Receive 10% Off all orders with your offer code.",
            cadence: "anytime",
            offerId: "345678",
          },
        ],
        regularOffer: [
          {
            title: "20%",
            cost: 10,
            shortDescription: "20% off one order!",
            longDescription: "Receive 20% off an order with your offer code.",
            cadence: "regularOnce",
            offerId: "001234567",
          },
        ],
        currency: "USD",
        currencyIcon: "$",
        monthlyGoalType: "cash",
        monthlyGoalCash: 1000,
        monthlyGoalPeople: 100,
        currentSupportCash: 750,
        currentSupportPeople: 80,
        liked: true,
      }, 
      {
        thumbnailImage: "https://images.unsplash.com/photo-1546173159-315724a31696?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
        name: "Mary's Juices", 
        shortDescription: "Healthy Eating for You",
        deal: "10%",
        liked: true,
      },
      {
        thumbnailImage: "https://images.unsplash.com/photo-1529193591184-b1d58069ecdd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80",
        name: "Good Eats", 
        shortDescription: "Ribs and Steaks",
        deal: "12%",
        liked: true,
      },
      {
        thumbnailImage: "https://images.unsplash.com/photo-1506084868230-bb9d95c24759?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
        name: "Hot Cakes!", 
        shortDescription: "The Best Breakfast Joint",
        deal: "25%",
      },
      {
        thumbnailImage: "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60",
        name: "Pizza Time", 
        shortDescription: "It's Pizza O'Clock!",
        deal: "5%",
      }
    ], 
    "Hardware Stores": [
        {
          thumbnailImage: "https://images.unsplash.com/photo-1581783898377-1c85bf937427?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=658&q=80",
          name: "The Hammer", 
          shortDescription: "Construction Supplies",
          liked: true,
        }, 
        {
          thumbnailImage: "https://images.unsplash.com/photo-1519520104014-df63821cb6f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
          name: "Nails and Sales", 
          shortDescription: "For the DIYer",
          deal: "12%",
          liked: true,
        },
        {
          thumbnailImage: "https://images.unsplash.com/photo-1544456731-e182851e1854?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
          name: "All of the Lights", 
          shortDescription: "Turn it up!",
          deal: "8%",
          liked: true,
        },
        {
          thumbnailImage: "https://images.unsplash.com/photo-1497961621566-ee46d8b8549c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
          name: "Home Base", 
          shortDescription: "Household needs!",
        }
      ], 
      "General Goods": [
        {
          thumbnailImage: "https://images.unsplash.com/photo-1472851294608-062f824d29cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
          name: "Big Dollar", 
          shortDescription: "Great Deals!",
          deal: "20%",
          liked: true,
        }, 
        {
          thumbnailImage: "https://images.unsplash.com/photo-1473187983305-f615310e7daa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
          name: "It's all Good!", 
          shortDescription: "Everything!",
          deal: "12%",
        },
        {
          thumbnailImage: "https://images.unsplash.com/photo-1489348611450-4c0d746d949b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80",
          name: "Claire's Antiques", 
          shortDescription: "Great Finds",
        },
        {
          thumbnailImage: "https://images.unsplash.com/photo-1531540823824-7d09de6461c8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60",
          name: "Planters", 
          shortDescription: "Garden Goods",
        }
      ], 
  }

  
  export default dummyBusinesses;
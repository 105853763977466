import React from 'react';

import SLLink from '../components/SLLink';

import '../styles/_footer.css';

export default function Footer () {

    return (
        <div className="spendlocal-footer">
            <SLLink 
                trackingInfo={{name: "VIEW_TERMS"}}
                className="spendlocal-footer-link" 
                to='/terms'> Terms and Conditions </SLLink>
            <SLLink 
                trackingInfo={{name: "VIEW_PRIVACY_POLICY"}}
                className="spendlocal-footer-link" 
                to='/privacy'> Privacy Policy </SLLink>
            <span className="spendlocal-footer-link">© 2020 SPENDLOCAL LLC</span>
        </div> 
    )
}
import React from 'react';

import '../styles/_businesscarousel.css';

export default function BusinessCarousel (props) {
  return (
    <div className="business-carousel-container">
      <div className="business-carousel-title">
        {props.title}
      </div>
      <div className="business-carousel">
        {props.children}
      </div>
      {props.comingSoon && <div className="business-carousel-coming-soon">Coming Soon!</div>}
    </div>
  )
}
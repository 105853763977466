import React, { useState } from 'react';

import '../styles/_dollarchoice.css';

let classNames = require('classnames');
export default function DollarChoice (props) {

    return (
        <button 
        type="button"
        onClick={() => props.setChosenCost(props.cost)}
        className={classNames("dollar-choice-button", {"selected": props.chosenCost === props.cost}, {"minimumForBenefit": props.minBenefit <= props.cost})}>
           {`$${props.cost}`}
        </button>
    )
    
} 
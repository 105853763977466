import React from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import LoadingButton from '../components/LoadingButton';
import * as Yup from 'yup';

import { connect } from 'react-redux';
import { resetPassword } from '../redux/actions/userActions';

import '../styles/_signin.css';
import '../styles/_resetPassword.css';
import SLButton from '../components/SLButton';

const resetPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .required('No email provided')
    .email('Must be a valid email.'),
});

class ResetPassword extends React.Component {
    
  state = {
    loading: false,
  }

  defaultValues = {
    email: "",
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI) {
      this.setState({ 
        message: nextProps.UI.message,
        errors: nextProps.UI.errors,
        loading: nextProps.UI.loading,
      })
    }
  }

  handleResetPassword = (values) => {
    this.props.resetPassword(values);
  }

  render () {
    return (
      <div className="page-body">
        <div className="reset-password-container">
          <Formik
              initialValues={this.defaultValues}
              validationSchema={resetPasswordValidation}
              onSubmit={(values, actions) => {
                  this.handleResetPassword(values);
                  actions.setSubmitting(false);
              }}>
              <Form className="sign-in-form reset-password-form">

                  <h2 className="reset-password-title">Forgot your password?</h2>
                  <h4 className="reset-password-title">Enter your email here and we'll send you an email to reset it.</h4>
                  <label className="sign-in-form-label mobile" htmlFor="email">Email</label>
                  <Field className="sign-in-form-input mobile" type="text" name="email" />
                  <ErrorMessage className="sign-in-form-error mobile" name="email" component="div" />

                  <div className="sign-in-form-error" component="div">
                    {this.state.errors && 
                    this.state.errors.data && 
                    this.state.errors.data.general}
                  </div>

                  <div className="reset-password-title" component="div">
                    {
                      this.state.message && 
                      this.state.message.message
                    }
                  </div>

                  <SLButton
                    trackingInfo={{name: "RESET_PASSWORD"}}
                    type="submit" 
                    className="sign-in-submit-button mobile" 
                    loading={this.state.loading}>Reset Password</SLButton>
              </Form>
          </Formik>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  UI: state.UI,
})

const mapActionsToProps = ({
  resetPassword
})

export default connect(mapStateToProps, mapActionsToProps)(ResetPassword);

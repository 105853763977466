import {
    CREATE_PAYMENT_INTENT, 
    LOADING_UI_ELEMENT,
    SET_ERRORS
} from '../types';

import axios from 'axios';

export const createPaymentIntent = (paymentDetails) => (dispatch) => {

    dispatch({ 
        type:  LOADING_UI_ELEMENT,
        payload: 'paymentIntent'
    });

    axios
    .post('/payments/createIntent', paymentDetails)
    .then((res) => {
        dispatch({
            type: CREATE_PAYMENT_INTENT,
            payload: res.data
        });
    })
    .catch((err) => {
        dispatch({
            type: SET_ERRORS, 
            payload: err.response
        });
    });
};

export const cancelPaymentIntent = (clientSecret) => (dispatch) => {
    axios
    .post('/payments/cancelIntent', clientSecret)
    .then((res) => {
        console.log("Payment intent cancelled.")
    })
    .catch((err) => {
        console.log("errs", err);
        dispatch({
            type: SET_ERRORS, 
            payload: err.response
        });
    });
};
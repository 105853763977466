import React from 'react';

import GoSpendLocalLogo from '../resources/GoSpendLocal_logo.svg';

import '../styles/_home.css';
import SLLink from '../components/SLLink';

import iconSmallBusiness from '../resources/icons/small-business.svg';
import iconOrganization  from '../resources/icons/organization.svg';
import iconDollar        from '../resources/icons/dollarOutline.svg';
import iconDollarCircle  from '../resources/icons/dollarCircleOutline.svg';
import iconCoupon        from '../resources/icons/couponOutline.svg';
import iconHelp          from '../resources/icons/help.svg';
import iconAdd           from '../resources/icons/add.svg';
import iconExchange      from '../resources/icons/exchange.svg';

export default function Home () {
  return (
    <div className="home-container">
      <img alt="Logo that saya go spend local in alternating green and dark grey colors" id="home-logo" src={GoSpendLocalLogo} /> 
      <div className="home-paragraph">Where <span className="home-emphasis">small businesses</span> get paid to help causes in their area through crowdfunded support, and locals <span className="home-emphasis">get great deals</span> for pitching in!</div>
      <SLLink
        trackingInfo={{name: "VIEW_SIGNUP_FROM_HOME"}}
        to="signup"
        className="home-button">
        Get Started
      </SLLink>
      <div className="home-description-container">
        <h1 className="home-title">How does it work?</h1>
        <ol className="home-list">
          <div className="home-icon-container">
            <img alt="small business illustration with awning" className="home-icon" src={iconSmallBusiness}/>
            <img alt="illustration of plus symbol" className="home-icon small" src={iconAdd}/>
            <img alt="illustration of hand holding heart" className="home-icon" src={iconOrganization}/>
          </div>
          <li className="home-list-item">Small businesseses choose a cause to support in their area.</li>

          <div className="home-icon-container">
            <img alt="illustration of dollar symbol" className="home-icon" src={iconDollar}/>
            <img alt="illustration of two arrows pointing in opposite direction left to right" className="home-icon small" src={iconExchange}/>
            <img alt="illustration of coupon with dollar symbol" className="home-icon" src={iconCoupon}/>
          </div>

          <li className="home-list-item">Locals support these businesses with monetary pledges in exchange for discounts and free items.</li>
          
          <div className="home-icon-container">
            <img alt="illustration of two hands holding the forearms of the other" className="home-icon" src={iconHelp}/>
          </div>

          <li className="home-list-item">Small businesses use the funds to pay themselves and help these causes!</li>
        </ol>
      </div>
      <div className="home-description-container">
        <h1 className="home-title">This isn't just charity.</h1>

        <div className="home-icon-container">
          <img alt="illustration of dollar symbol in a circle" className="home-icon" src={iconDollarCircle}/>
        </div>

        <div className="home-text-body">The support that small businesses receive on GoSpendLocal is real revenue. Businesses can pay themselves at a sustainable rate, while helping these awesome non-profit causes, creating a self-supporting local economy in their community. </div>
      </div>
      <SLLink
        trackingInfo={{name: "VIEW_SIGNUP_FROM_HOME"}}
        to="signup"
        className="home-button">
        Get Started
      </SLLink>
    </div>
  )
}

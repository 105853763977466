import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import '../styles/_slbutton.css';

var classNames = require('classnames');

export default function SLButton (props) {

  function trackClick (e) {
    props.onClick && props.onClick(e);
    props.trackingInfo && window.analytics.track("BUTTON: " + props.trackingInfo.name, props.trackingInfo.info);
  }

  return (
    <button 
    {...props} onClick={trackClick} className={classNames("slbutton", props.className)}>
      {
        props.loading ? 
          <PulseLoader color={props.loadingColor || '#000000'}/> :
          props.children
      }
    </button>
  );
}
import React from 'react';
import dummyBusinesses from '../util/defaultDetail';

import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import LocalBusinessGoalBar from '../components/LocalBusinessGoalBar';
import FavoriteButton from '../components/FavoriteButton';
import CauseTag from '../components/CauseTag';
import CauseTagMobile from '../components/CauseTagMobile'; 
import DoesNotExist from '../pages/DoesNotExist'; 
import LoadingPage from '../components/LoadingPage';
import SLLink from '../components/SLLink';

// Redux
import { connect } from "react-redux";
import { getBusiness, resetBusiness, likeBusiness, unlikeBusiness, getCause } from '../redux/actions/dataActions';

import '../styles/_localbusiness.css';
import '../styles/_localbusinessmobile.css';

// Borrowed from https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}


class LocalBusiness extends React.Component {
  componentWillMount = () => {
    const currentBusinessId = this.props.match.params.businessId;

    if (currentBusinessId === "undefined") {
      this.props.resetBusiness();
    }

    if (currentBusinessId && 
        currentBusinessId !== this.props.data.business.businessId) {
        console.log("Getting Business", currentBusinessId)
        this.props.getBusiness(currentBusinessId);
      }
  }

  componentWillUpdate = () => {

    let business = this.props.data.business;
    let cause = this.props.data.cause;
    let { UI } = this.props;

    if (business && cause) {
      if (
      (business.featuredCauseId) && 
      (business.featuredCauseId !== "") && 
      (business.featuredCauseId !== cause.causeId) &&
      (!UI.elementsLoading.cause)) {
        this.props.getCause(business.featuredCauseId);
      }
    }
  }

  buildAddressLink = (address, zipCode) => {
    let fullAddress = address + " " + zipCode;
    return 'https://www.google.com/maps/place/' + fullAddress.split(' ').join('+');
  }

  renderMobilePage = () => {
    let business = dummyBusinesses['Restaurants'][0];
    if (Object.keys(this.props.data.business).length > 0) {
      business = this.props.data.business;
    }

    let userLikes = this.props.user.likes;
    let cause = this.props.data.cause;

    return (
      <div className="mobile-page-body">
        <div className="business-mobile-image-container">
          <img className="business-mobile-cover-image" src={business.coverImage} />
          <FavoriteButton 
              noText={true}
              liked={userLikes.findIndex(like => like.businessId === business.businessId) > -1}
              favorite={() => (this.props.likeBusiness(business.businessId))}
              unfavorite={() => (this.props.unlikeBusiness(business.businessId))}/>
        </div>
        <div className="business-mobile-contact-container business-mobile-section">
          <span className="business-mobile-contact-name">{business.name}</span>
          <div className="business-mobile-contact-items">
            <div className="business-mobile-contact-column">
              {
              business.website && 
              <span className="business-mobile-contact-item">
                <a 
                onClick={() => window.analytics.track("VIEW_BUSINESS_EXTERNAL_WEBSITE", {website: business.website})}
                className="business-mobile-contact-link" 
                target='_blank' 
                rel="noopener noreferrer" 
                href={business.website}>
                  <LanguageIcon className="local-business-contact-icon" />Our Website
                </a>
              </span>
              }
              {
                business.phone && 
                <span className="business-mobile-contact-item">
                  <a 
                  onClick={() => window.analytics.track("VIEW_BUSINESS_PHONE", {phone: formatPhoneNumber(business.phone)})}
                  className="business-mobile-contact-link" 
                  href={"tel:" + business.phone}>
                    <PhoneIcon className="local-business-contact-icon" /> {formatPhoneNumber(business.phone)} </a>
                </span>
              }
            </div>
            <div className="business-mobile-contact-column">
              {
                business.address &&
                <span className="business-mobile-contact-item">
                  <a 
                  onClick={() => window.analytics.track("VIEW_BUSINESS_ADDRESS", {address: this.buildAddressLink(business.address, business.zipCode)})}                  
                  className="business-mobile-contact-link" 
                  target='_blank' 
                  rel="noopener noreferrer" 
                  href={this.buildAddressLink(business.address, business.zipCode)}>
                    <BusinessIcon className="local-business-contact-icon" />Our Address</a>
                </span>
              }
            </div>
          </div>
        </div>
        <CauseTagMobile business={business} cause={cause} />
        <div className="business-mobile-descripton-container business-mobile-section">
          <div className="business-mobile-about-us">About Us</div>
          <p className="business-mobile-description">
            {business.longDescription}
          </p>
        </div>
      </div>
    );
  }

  render () {
    let { UI } = this.props;
    if (UI.loading || UI.elementsLoading.business) {
      return (
        <LoadingPage />
      )
    } 
    
    if (this.props.UI.errors && this.props.UI.errors.status === 404) {
      // TODO, turn 404 on when needed.
      return ( 
        <DoesNotExist />
      )
    }

    if ((Object.keys(this.props.data.business).length === 0)) {
      return ( 
        <DoesNotExist />
      )
    }
    // Grab a dummy business if this business doesn't exist.
    let business = this.props.data.business;

    let { 
      name, 
      coverImage, 
      longDescription, 
      website, 
      phone, 
      address, 
      yearStarted, 
      businessId, 
      zipCode 
    } = business;

    let userLikes = this.props.user.likes;

    let cause = this.props.data.cause;

    if (this.props.isMobile) {
      return this.renderMobilePage();
    }

    return (
      <div className="page-body">
        <div className="local-business-top-container">
          <div className="local-business-info-container">
            <div className="local-business-cover-image-container">
              <FavoriteButton 
              liked={userLikes.findIndex(like => like.businessId === businessId) > -1}
              favorite={() => (this.props.likeBusiness(businessId))}
              unfavorite={() => (this.props.unlikeBusiness(businessId))}/>
              <img 
                alt="large business photo" 
                className="local-business-cover-image"
                src={coverImage} />
            </div>
            <div className="local-business-basic-info-container">
              <span className="local-business-name">{name}
                { yearStarted && <span className="local-business-year-started">since {yearStarted}</span>}
              </span>
              <span className="local-business-contact-info">
                {
                  website &&
                  <a 
                  onClick={() => window.analytics.track("VIEW_BUSINESS_WEBSITE", {website: website})}                  
                  className="local-link" target='_blank' rel="noopener noreferrer" href={website}>
                    <span className="local-business-contact-item">
                      <LanguageIcon className="local-business-contact-icon" /> Our Website 
                    </span>
                  </a>
                }

                {
                  phone &&
                  <span className="local-business-contact-item">
                    <a 
                    onClick={() => window.analytics.track("VIEW_BUSINESS_PHONE", {phone: formatPhoneNumber(phone)})}                  
                    className="local-link" href={"tel:" + phone}>
                      <PhoneIcon className="local-business-contact-icon" /> {formatPhoneNumber(phone)} 
                    </a>
                  </span>
                }

                {
                  address && 
                  <span className="local-business-contact-item">
                    <a 
                    onClick={() => window.analytics.track("VIEW_BUSINESS_ADDRESS", {address: this.buildAddressLink(business.address, business.zipCode)})}                  
                    className="local-link" target='_blank' rel="noopener noreferrer" href={this.buildAddressLink(address, zipCode)}>
                      <BusinessIcon className="local-business-contact-icon" /> {address + " " + zipCode} 
                    </a>
                  </span>
                }
              </span>

              <hr />

              <span className="local-business-long-description">
                {longDescription}
              </span>
            </div>
          </div>
          <div className="local-business-offer-container">
            <LocalBusinessGoalBar />
            <div className="local-business-offers">
              {/* {
                !UI.elementsLoading.cause &&
                cause &&
                <div className="local-business-cause-call-to-action">
                  {`Support Our Cause!`}
                </div>
              } */}
              {
                !UI.elementsLoading.cause &&
                cause &&
                <SLLink
                  trackingInfo={{name: "VIEW_CAUSE_FROM_BUSINESS", info: {causeLink: `/biz/${business.businessId}/support/${cause.causeId}`}}}
                  className="local-business-cause-tag-support-link" 
                  to={`/biz/${business.businessId}/support/${cause.causeId}`}>
                  <CauseTag support={true} cause={cause} />
                </SLLink>

              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  UI: state.UI,
});

export default connect(mapStateToProps, 
  { 
    getCause,
    getBusiness, 
    resetBusiness, 
    likeBusiness, 
    unlikeBusiness 
  })(LocalBusiness);

  

import React from 'react';


export default function TermsAndConditions () {
    return (
        <div style={{'textAlign':'left'}}>
            <p>
<strong style={{'textAlign':'center'}}>Terms and Conditions of SpendLocal </strong>
</p>
<p>
<strong>COVID-19 NOTICE</strong>- As SpendLocal looks to help individuals and
businesses impacted by COVID-19, it is partnering with various third parties to
help Users cross promote campaigns in order to drive more support. Such third
parties that provide or facilitate links, tools, widgets or other features allow
You to access other sites, Service and resources provided by such third parties
but also allow such third parties to access campaigns and distribute campaigns
created on SpendLocal without obtaining Your consent. By starting a SpendLocal
and applying to be part of the various COVID-19 initiatives, You consent to your
Campaign being shared on each such third party site and You agree to be bound by
their respective privacy policies and terms of service.
</p>
<p>
Subject to these Terms of Service, as amended from time to time (“Terms of
Service”), SPENDLOCAL, LLC (“SpendLocal”) provides (a) the SpendLocal platform
to you through its website at www.GoSpendLocal.com and attendant mobile
applications (the "SpendLocal Platform") and related Service (collectively, the
SpendLocal Platform, including any new features and applications, the
"SpendLocal Service"). If you are a campaign organizer (as defined below),
beneficiary to a campaign, comment contributor, or Pledger (collectively
referred to herein as a “User”) located in the United States, you are
contracting with SpendLocal LLC, 169 Commack Rd, suite H #381, Commack, NY
11725. Unless specifically indicated otherwise, for purposes of the following
Terms of Service, “SpendLocal,” “we,” “us,” “our,” and other similar terms,
shall refer to the party with whom you are contracting.
</p>
<p>
ARBITRATION, JURY TRIAL, AND CLASS ACTION WAIVER: EXCEPT AS OTHERWISE DESCRIBED
IN THE DISPUTES SECTION BELOW, BY USING THE SPENDLOCAL SERVICE (REFERRED HEREIN
AS “SERVICE”), YOU AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED BY BINDING,
INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO A JURY TRIAL OR TO
PARTICIPATE IN A CLASS ACTION. PLEASE REVIEW THE DISPUTES SECTION CAREFULLY; BY
ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND
UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT.
</p>
<p>
We reserve the right, at our sole discretion, to change or modify portions of
these Terms of Service at any time. When we do this, we will post the revised
Terms of Service on this page and will indicate the date of such revision.
</p>
<p>
Your continued use of the Service after the date of any such changes constitutes
your acceptance of the new Terms of Service. To the extent allowed by law, the
English version of these Terms of Service is binding and other translations are
for convenience only. If you do not wish to accept the new Terms of Service, you
may discontinue your use of the Service.
</p>
<p>
In addition, when using the Service, you will be subject to additional
applicable policies including without limitation, the<a
href="https://www.gospendlocal.com/privacy"> Privacy Policy</a>. All such terms
are hereby incorporated by reference into these Terms of Service (provided,
however, these Terms of Service will take precedence in the event of conflict).
We shall resolve any such conflicts in our sole discretion, and all of our
determinations are final.
</p>
<p>
Access and Use of the Service
</p>
<p>
<strong>SpendLocal Service Description:</strong> The SpendLocal Service are
offered as a platform to allow an individual business (the "Campaign Organizer")
to post a fundraising campaign ("Campaign") to the SpendLocal Platform to accept
monetary support ("Pledges") from Pledgers (“Pledgers").
</p>
<p>
<strong>Payment Processor, Platform, And Other Fees:</strong> Although there are
no fees to set up a Campaign, industry-standard payment processor fees apply
(hereinafter and on the website referred to as "Payment Processor Fees").
Additionally, our Service charges fees including but not limited to: transaction
fees, promotional fees, and platform fees.Our service reserves the right to
change these fees at any time, without consulting any user’s, and can add
additional fees it deems necessary in the future. To learn more about the
SpendLocal Platform, and applicable Payment Processor Fees visit<a
href="https://www.gospendlocal.com/pricing"> SpendLocal Pricing</a>
</p>
<p>
<strong>The Service is a Platform; We are not a Broker, Financial Institution,
Creditor or Charity:</strong> The Service is an administrative Platform only.
SpendLocal facilitates the Campaign of the Campaign Organizers and permits
Pledgers to make Pledges to these Campaign Organizers. SpendLocal is not a
broker, agent, financial institution, creditor or 501(c)(3) nonprofit
corporation.
</p>
<p>
All information and content provided by SpendLocal relating to the Service is
for informational purposes only, and SpendLocal does not guarantee the accuracy,
completeness, timeliness or reliability of any such information or content. No
content is intended to provide financial, legal, tax or other professional
advice. Before making any decisions regarding any Campaigns, Charities, Pledges,
Pledgers, or any information or content relating to the Service, you should
consult your financial, legal, tax or other professional advisor as appropriate.
You acknowledge that all information and content accessed by you using the
Service is at your own risk.
</p>
<p>
SpendLocal has no control over the conduct of, or any information provided by, a
User and hereby disclaims all liability in this regard to the fullest extent
permitted by applicable law. We do not guarantee that a Campaign will obtain a
certain amount of Pledges or any Pledges at all. We do not endorse any Campaign,
User, or cause and we make no guarantee, express or implied, that any
information provided through the Service is accurate. We expressly disclaim any
liability or responsibility for the outcome or success of any Campaign. You, as
a Pledgers, must make the final determination as to the value and
appropriateness of contributing to any User, Campaign, or event.
</p>
<p>
<strong>No Solicitation:</strong> The SpendLocal Platform is offered to help
Campaign Organizers raise money. SpendLocal merely provides the technology to
allow fundraisers to connect with Pledgers. The existence of the Service is not
a solicitation of Pledges by SpendLocal, and SpendLocal does not engage in any
solicitation activities, or consult on the solicitation of contributions from
the public, on behalf of any individual, entity, or organization. By using the
Service, you understand and agree that SpendLocal shall not be responsible for
the use of your Pledges or the amount of funds raised for the User, Campaign, or
event.
</p>
<p>
<strong>Pledgers:</strong> All Pledges are at your own risk. When you make a
Pledge through the Platform, it is your responsibility to understand how your
money will be used. SpendLocal is not responsible for any offers, promises,
rewards or promotions made or offered by Users or Campaigns. We do not and
cannot verify the information that Users or Campaigns supply, nor do we
represent or guarantee that the Pledges will be used in accordance with any
fundraising purpose prescribed by a User or Campaign or in accordance with
applicable laws. Notwithstanding the foregoing, we take possible fraudulent
activity and the misuse of funds raised very seriously. If you have reason to
believe that a User or Campaign is not raising or using the funds for their
stated purpose, please contact us at contact@gospendlocal.com
</p>
<p>
Pledgers are not permitted to impose restrictions on the use of Pledges by a
Event Organizer. To the extent that a Pledge is made in response to an appeal
for a particular program of a Event Organizer, or to the extent that a Pledgers
purports to direct the use of Pledges by a Event Organizer, any such directions
shall constitute non-binding recommendations only and the Event Organizer shall
have full discretion to determine how all Pledges will be used.
</p>
<ol>
<li>SpendLocal makes no representation as to whether all or any portion of your
Pledges, including, if any, Payment Processor Fees, are tax deductible or
eligible for tax credits. SpendLocal will have no liability for any claim by any
federal, state, provincial, territorial, local or any other tax authority with
respect to the characterization on any applicable tax return of any Pledge by
you, any User or any Charity. You should consult your tax advisor as to the
amount of your Pledge that is tax deductible or eligible for tax recognition,
having regard to (among other things) the tax status of the recipient of any
Pledge in any relevant jurisdiction, and particularly if you are awarded a gift
or prize in connection with your Pledge.
</li>
</ol>
<p>
<strong>Campaign Organizer:</strong> You, as a Campaign Organizer, represent,
warrant, and covenant that (i) all information you provide in connection with a
Campaign or Beneficiary is accurate, complete, and not likely to deceive
reasonable Users; (ii) all Pledges contributed to your Campaign will be used
solely as described in the materials that you post or otherwise provide; (iii)
if you withdraw Pledges believed by reasonable Pledgers to be raised on behalf
of someone other than you (i.e., the Beneficiary), all Pledges will be given to
and/or spent on behalf of the Beneficiary; (iv) if you add a Beneficiary through
the Service, you relinquish control of the Pledges, including the ability to
issue refunds; (v) you will not infringe the rights of others; (vi) you will
comply with all relevant and applicable law and financial reporting obligations,
including but not limited to laws and regulations relating to registration, tax
reporting, political contributions, and asset disclosures for your project; and
(vii) to the extent you share with us any personal data of any third party for
any purpose, including the names, email addresses and phone numbers of your
personal contacts, you have the authority (including any necessary consents), as
required under applicable law, to provide us with such personal data and allow
us to use such personal data for the purposes for which you shared it with us.
You authorize SpendLocal, and SpendLocal reserves the right to, provide
information relating to your Campaign to Pledgers, beneficiaries of your
Campaign or law enforcement, and to assist in any investigation thereof.
</p>
<p>
<strong>Your Registration Obligations:</strong> You may be required to register
with SpendLocal in order to access and use certain features of the Service. If
you choose to register for the Service, you agree to provide and maintain true,
accurate, current and complete information about yourself or your entity as
prompted by the Service' registration form. Campaign Organizers must register
using their true identities (or the identities of the Charities’ authorized
representatives), including their name, address and any image or video
purporting to depict the Campaign Organizer or the Beneficiary of such campaign.
You agree to keep registration information current and up to date.
</p>
<p>
Registration data and certain other information about you are governed by these
Terms of Service and our Privacy Policy. If you are under 13 years of age (16 in
Europe), you are not authorized to use the Service, with or without registering.
In addition, if you are under the age of majority in your jurisdiction
(typically 18 or 19 years of age), you may use the Service, with or without
registering, only with the approval of your parent or guardian. Certain aspects
of our Service may also require you to register with, and agree to the terms of,
third-party service providers (e.g., payment processors), with whom SpendLocal
has entered into contracts, in order to be able to benefit from their Service.
If SpendLocal or one of our payments processors at any time discovers that the
information you provided about you or the purpose of your Campaign is incorrect
or violates any of these Terms of Service or their terms of service, the Service
may be suspended and/or terminated with immediate effect and fines may be
applied by the relevant authorities which will in all such cases be payable by
you. You acknowledge and agree that the use of third party payment processors
that are integral to the Service and that we may exchange information with such
third parties in order to facilitate the provision of Service as set out in our
Privacy Policy.
</p>
<p>
<strong>Ownership of Pledgers Lists and Data</strong>
</p>
<ol>
<li>SpendLocal Platform. As between SpendLocal and entity using the SpendLocal
Platform, entity may request Pledgers Lists (defined below) and Pledgers Data
(defined below) for compliance and transactional purposes.</li>
<li>SpendLocal Platform. As between SpendLocal and entity using the SpendLocal
Platform, with respect to Pledger lists and Pledger Data that SpendLocal has
received exclusively by means of a Pledge to such entity and by no other means,
and to the extent permitted by the Privacy Policy and applicable law, entity
shall own the Pledger Data of Pledgers who have provided a Pledge to entity,
including contact information of such Pledgers; provided, however, that entity
grants to SpendLocal a worldwide, non-exclusive, royalty-free, perpetual license
to use Pledger Data for the following purposes: (a) to provide the Services,
including account administration and processing Pledges; (b) to enforce our
agreements; (c) fraud prevention, safety, security, and compliance with law; (d)
to respond to law enforcement requests; (e) research and analytics; (f) to
customize, adjust, and improve the Services; and (g) to develop new services.
“Pledger Data” shall mean a Pledger’s name, address, email address, Pledge
amount, date of transaction, transaction identification number, name of the
project, and event associated with the Pledge. THE LIST OF PLEDGERS WHO
CONTRIBUTE TO A ENTITY'S CAMPAIGN OR EVENT CONDUCTED THROUGH THE SERVICES
(“PLEDGER LISTS”) ARE PROVIDED “AS IS”, AND SpendLocal MAKES NO REPRESENTATIONS,
WARRANTIES OR GUARANTEES ABOUT THE ACCURACY, COMPLETENESS OR TIMELINESS OF ANY
PLEDGER LIST OR ANY INFORMATION CONTAINED THEREIN. By using these Services, all
Pledgers consent to the disclosure of such Pledger Lists and Pledger Data
described in this Section.
</li>
</ol>
<p>
<strong>Taxes:</strong> It is your responsibility to determine what, if any,
taxes apply to the Pledges you receive through your use of the Service. It is
solely your responsibility to assess, collect, report or remit the correct tax,
if any, to the appropriate tax authority.
</p>
<p>
<strong>Member Account, Password and Security:</strong> You are responsible for
maintaining the confidentiality of your password and account, if any, and are
fully responsible for any and all activities that occur under your password or
account. You agree to (a) immediately notify SpendLocal of any unauthorized use
of your password or account or any other breach of security, and (b) sign out
from your account at the end of each session when accessing the Service.
SpendLocal will not be liable for any loss or damage arising from your failure
to comply with this Section.
</p>
<p>
<strong>Modifications to Service:</strong> SpendLocal reserves the right to
modify, suspend or discontinue, temporarily or permanently, the Service (or any
part thereof) at any time and for any reason, with or without notice, and
without any liability to you or to any third party for any claims, damages,
costs, or losses resulting therefrom.
</p>
<p>
Content Manifestly Made Public by the User.
</p>
<p>
<strong>Public Content; Public Display of Information and Pledges:</strong> Some
of your activity on and through the Service is public, such as content you post
publicly on the Platform (including descriptions, texts, music, sound,
information, data, software, graphics, comments, photos, videos, images,
trademarks, logos, brands or other materials you upload or post through the
Service or share with other users or recipients) (“User Content”). Additionally,
user profile information, including your first and last name, public email
address, organization, personal biography, and other information you enter in
connection with your user profile may be displayed to other users to facilitate
user interaction within the Service. For example, as a Campaign Organizer, you
might post your personal data - such as information about a recent hospital stay
- which data might be considered sensitive data. In addition, as a Pledgers, you
have the option to publicly display your Pledge for all to see, including on
search engines (like Google and Yahoo). Please remember that if you choose to
provide information using certain public features of the Service, then that
information is governed by the privacy settings of those particular features and
may be publicly available. Individuals reading such information may use or
disclose it to other individuals or entities without our knowledge and without
your knowledge, and search engines may index that information. We therefore urge
you to think carefully about including any specific information you may deem
private in content that you create or information that you submit through the
Service. Please see our<a href="https://www.gospendlocal.com/privacy"> Privacy
Policy</a> for information on the ways that we may collect, use, and store
certain information about you and your use of the Service.
</p>
<p>
<strong>Unsolicited Information:</strong> Please be advised that User Content
and other unsolicited information you provide may be publicly accessible, such
as information you post in forums or comment sections. We also collect
information through customer support communications, your communications to us
of ideas for new products or modifications to existing products, and other
unsolicited submissions, or any questions, comments, suggestions, ideas,
feedback or other information about the Service (collectively, with
publicly-accessible information, “Unsolicited Information”). By sending us
Unsolicited Information, (a) you agree that we are under no obligation of
confidentiality, expressed or implied, with respect to the Unsolicited
Information; (b) you acknowledge that we may have something similar to the
Unsolicited Information already under consideration or in development; (c) you
agree that SpendLocal will be entitled to the unrestricted use and dissemination
of the Unsolicited Information for any purpose, commercial or otherwise, without
acknowledgment or compensation to you; (d) you represent and warrant that you
have all rights necessary to submit the Unsolicited Information; (e) to the
extent necessary, you hereby grant to SpendLocal a fully paid, royalty-free,
perpetual, irrevocable, worldwide, non-exclusive, and fully transferable and
sublicensable right (through multiple tiers) and license to use, reproduce,
perform, display, distribute, adapt, modify, re-format, create derivative works
of, and otherwise commercially or non-commercially exploit in any manner, any
and all Unsolicited Information, and to sublicense the foregoing rights; and (f)
you irrevocably waive, and cause to be waived, against SpendLocal and its users
any claims and assertions of any moral rights contained in such Unsolicited
Information. This Unsolicited Information section shall survive any termination
of your account or the Service.
</p>
<p>
You acknowledge and agree that SpendLocal may preserve Unsolicited Information,
as well as User Content, and may also disclose your Unsolicited Information or
User Content if required to do so by law or in the good-faith belief that such
preservation or disclosure is reasonably necessary to (a) comply with legal
process, applicable laws or government requests; (b) enforce these Terms of
Service; (c) respond to claims that any User Content violates the rights of
third parties; or (d) protect the rights, property, or personal safety of
SpendLocal, its users or the public.
</p>
<p>
<strong>Third-Party Communications:</strong> If you use any feature of the
Service that allows you to communicate with third parties (such as to refer a
third party to the Service or to communicate with them regarding a Campaign or a
Pledge), either by submitting Third-Party Data to the Service or otherwise
permitting the Service to automatically access Third-Party Data in your
possession, you acknowledge and agree that you have the authority of the
relevant third party for us to access and use the relevant Third-Party Data and
that you have notified these third parties and informed them how their
information is collected and used by SpendLocal to provide the Service. We
reserve the right to identify you as the person who has made the referral in any
messages that are sent to them. We use Third-Party Data to (a) contact such
third party using the Third-Party Data provided, and/or (b) provide you with an
editable template message designed to facilitate communications between you and
such third party through the Service. In addition to sending the foregoing
communications we may also send reminders or related messages to you and to
third parties on your behalf from time to time where permitted by applicable
law. In each case, any such communication sent to third parties using
Third-Party Data will provide a means to “opt out” of receiving further
communication of the same nature.
</p>
<p>
<strong>Promotions on the SpendLocal Platform:</strong> You are not permitted to
offer any raffle, sweepstakes or similar activity (each, a "Promotion") on or
through the SpendLocal Service.
</p>
<p>
You are not permitted to offer any Promotion on the SpendLocal Platform without
our prior written consent. You may seek permission by sending an email to
contact@GoSpendLocal.com. If we consent, you take full responsibility for the
Promotion, and you agree that: (a) such Promotion shall comply with all
applicable laws; (b) you are solely responsible for all facets of the Promotion,
including without limitation any prizes offered; (c) you may not use the our
intellectual property in the rules or any other materials relating to the
Promotion without our express written permission; (d) such Promotion does not
require making a Pledge as the only way to enter; (e) you are responsible for
the marketing of the Promotion, and that such Promotion is not marketed to
anyone under either the age of 18 or the age of majority for the jurisdiction in
which you reside, whichever age is older; (f) prizes, rewards, give-aways or
incentives are not intended to be items available for purchase; and (g) such
Promotion may not endorse, sponsor or promote anything related to gambling,
alcohol, illegal or prescription drugs, medical devices, national health
products, firearms, pornography, or tobacco. You will include the following
provisions within your official rules for any Promotion that you choose to
publicize on SpendLocal Service: (i) SpendLocal does not sponsor or endorse the
Promotion; (ii) SpendLocal does not guarantee the delivery of any reward,
incentive or other prize offered by you; (iii) each participant or entrant in
the Promotion releases SpendLocal from any and all liability and (iv) all
questions concerning the Promotion must be directed to you and not to
SpendLocal.
</p>
<p>
If you or any other third party offer to match funds raised through SpendLocal
Service, you or such third party are solely responsible for providing those
matching funds and for complying with any legal obligations associated with such
a match. SpendLocal shall have no responsibility for providing such matching
funds, ensuring that you or the third party provides those funds, or ensuring
any relevant legal obligations are met.
</p>
<p>
<strong>Data Retention:</strong> You acknowledge that SpendLocal has no
obligation to you to retain data relating to any account or Campaign. You
acknowledge that SpendLocal reserves the right to delete data or to terminate
accounts or Campaigns at any time and for any reason, with or without notice,
and without any liability to you or to any third party for any claims, damages,
costs or losses resulting therefrom. The foregoing does not apply to Campaigns
or accounts started by Charities on either Platform, in which case SpendLocal
will provide reasonable notice where possible.
</p>
<p>
<strong>Mobile SpendLocal Service:</strong> The SpendLocal Service include
certain features that may be made available via a mobile device, including the
ability to (i) upload User Content to the SpendLocal Platform, (ii) browse the
SpendLocal Platform and (iii) access certain items through an application
downloaded and installed on a mobile device (collectively, the "Mobile
Service"). To the extent you access Mobile Service, your wireless service
carrier's standard charges, data rates and other fees may apply. In addition,
downloading, installing, or using certain Mobile Service may be prohibited or
restricted by your carrier, and not all Mobile Service may work with all
carriers or devices. By using the Mobile Service, you agree that we may
communicate with you by SMS, MMS, text message or other electronic means to your
mobile device and that certain information about your usage of the Mobile
Service may be communicated to us. We shall comply with any additional
requirements that may apply under local laws and regulations before
communicating with you in this manner. In the event that you change or
deactivate your mobile telephone number, you agree to promptly update your
SpendLocal account information to ensure that your messages are not sent to the
person that acquires your old number.
</p>
<p>
Prohibited Conduct
</p>
<p>
You are solely responsible for compliance with all applicable law in relation to
your Campaign or use of the Service. You are further solely responsible for all
User Content that you upload, post, publish, display, transmit or otherwise use
(hereinafter, "Upload"). If you are not the beneficiary of the Campaign you
organize, you agree to deliver funds to the ultimate beneficiary directly and as
soon as possible. You agree to fully cooperate with any request we make for
evidence we deem necessary to verify your compliance with these Terms of
Service.
</p>
<p>
The following are examples of User Content and/or use that is illegal or
prohibited by SpendLocal. This list is not exhaustive and we reserve the right
to remove any Campaign and/or investigate any User who, in our sole discretion,
violates any of the terms or spirit of these Terms of Service. As we investigate
Your Campaign, a User, or User Content, we may consider all available material
including but not limited to social media, related news, and any other
information that we, in our sole discretion, deem relevant in our review. We
further reserve the right, without limitation, to ban or disable your use of the
Service, remove the offending User Content, suspend or terminate Your account,
stop payments to any such Campaign, freeze or place a hold on Pledges, and
report you to law enforcement authorities or otherwise take appropriate legal
action including seeking restitution on behalf of ourselves and/or our users.
</p>
<p>
Without limiting the foregoing, you agree:
</p>
<ol>
<li>not to use the Service to raise funds or establish or contribute to any
Campaign with the implicit or explicit purpose of or involving:
<ol>
<li>the violation of any law, regulation, industry requirement, or third-party
guidelines or agreements by which you are bound, including those of payment card
providers and transaction processors that you utilize in connection with the
Service;</li>
<li>any election campaigns that are not run by a registered organization within
the supported country;</li>
<li>User Content or campaigns that are fraudulent, misleading, inaccurate,
dishonest, or impossible;</li>
<li>drugs, narcotics, steroids, controlled substances, pharmaceuticals or
similar products or therapies that are either illegal, prohibited, or enjoined
by an applicable regulatory body; legal substances that provide the same effect
as an illegal drug; or other products, medical practices, or any related
equipment or paraphernalia that have been found by an applicable regulatory body
to cause consumer harm;</li>
<li>knives, explosives, ammunition, firearms, or other weaponry or accessories;</li>
<li>annuities, investments, loans, equity or lottery contracts, lay-away
systems, off-shore banking or similar transactions, money service businesses
(including currency exchanges, check cashing or the like), pyramid schemes, “get
rich quick schemes” (i.e., Investment opportunities or other Service that
promise high rewards), network marketing and referral marketing programs, debt
collection or crypto-currencies;</li>
<li>gambling, gaming and/or any other activity with an entry fee and a prize,
including, but not limited to raffles, casino games, sports betting, fantasy
sports, horse or greyhound racing, lottery tickets, raffle tickets, auctions and
other ventures that facilitate gambling, games of skill or chance (whether or
not it is legally defined as a lottery), promotions involving monetary rewards,
including gift cards, or sweepstakes;</li>
<li>User Content that we deem, in our sole discretion, to be in support of hate,
violence, harassment, bullying, discrimination, terrorism, or intolerance of any
kind relating to race, ethnicity, national origin, religious affiliation, sexual
orientation, sex, gender or gender identity, or serious disabilities or
diseases;</li>
<li>Campaign that we deem, in our sole discretion, to be for the legal defense
of alleged crimes associated with hate, violence, harassment, bullying,
discrimination, terrorism, or intolerance of any kind relating to race,
ethnicity, national origin, religious affiliation, sexual orientation, sex,
gender or gender identity, serious disabilities or diseases, or financial crimes
or crimes of deception;</li>
<li>activities with, in, or involving countries, regions, governments, persons,
or entities that are subject to U.S. and other economic sanctions under
applicable law, unless such activities are expressly authorized by the
appropriate governmental authority;</li>
<li>funding a ransom, human trafficking or exploitation, vigilantism, bribes or
bounty;</li>
<li>pornography or other sexual content;</li>
<li>offensive, graphic, perverse or sensitive content;</li>
<li>the sale of items before the seller has control or possession of the item;</li>
<li>collecting payments on behalf of merchants by payment processors or
otherwise; including but not limited to self-payments on campaigns or an attempt
to bypass or otherwise circumvent the designated method of payment as provided
by SpendLocal;</li>
<li>credit repair or debt settlement Service;</li>
<li>the receipt or grant of cash advances or lines of credit to yourself or to
another person for purposes other than those purposes clearly stated in the
Campaign;</li>
<li>publication or removal of User Content (such as mug shots), where the
primary purpose of posting such User Content is to cause or raise concerns of
reputational harm;</li>
<li>sale or resale of a service without added benefit to the buyer; resale of
government offerings without authorization or added value;</li>
<li>aggregation of funds owed to third parties, factoring, or other activities
intended to obfuscate the origin of funds;</li>
<li>counterfeit music, movies, software, or other licensed materials without the
appropriate authorization from the rights holder;</li>
<li>products or Service that directly infringe or facilitate infringement upon
the trademark, patent, copyright, trade secrets, or proprietary or privacy
rights of any third party;</li>
<li>unauthorized sale or resale of brand name or designer products or Service;</li>
<li>sale of goods or Service that are illegally imported or exported;</li>
<li>processing where there is no bona fide Pledge accepted; cash advance; card
testing; evasion of card network chargeback monitoring programs;</li>
<li>collecting or providing funds for any purpose other than as described in a
Campaign description;</li>
<li>any other activity that SpendLocal may deem in its sole discretion to be in
support of individuals and/or entities associated with alleged financial crimes
including but not limited to corruption, bribery, tax evasion, fraud, and
activities of a similar nature; or</li>
<li>any other activity that SpendLocal may deem in its sole discretion to be
unacceptable.</li>
</ol>
<li>not to use the Service to transmit or otherwise upload any User Content that
(i) infringes any intellectual property or other proprietary rights of any
party; (ii) you do not have a right to upload under any law or under contractual
or fiduciary relationships; (iii) contains software viruses or any other
computer code, files or programs designed to interrupt, destroy or limit the
functionality of any computer software or hardware or telecommunications
equipment; (iv) p2oses or creates a privacy or security risk to any person; (v)
constitutes unsolicited or unauthorized advertising, promotional materials,
commercial activities and/or sales, "junk mail," "spam," "chain letters,"
"pyramid schemes," "contests," "sweepstakes," or any other form of solicitation;
or (vi) in the sole judgment of SpendLocal, is objectionable or which restricts
or inhibits any other person from using or enjoying the Service, or which may
expose SpendLocal or its users to any harm or liability of any type;</li></li>

<li>not to interfere with or disrupt servers or networks connected to the
Service, or disobey any requirements, procedures, policies or regulations of
networks connected to the Service;</li>
<li>not to harvest, collect or publish personally identifiable information of
others;</li>
<li>not to raise funds for a minor without the express permission of the minor’s
guardian unless the funds are transferred into a trust account for the sole
benefit of the minor;</li>
<li>not to use the Service on behalf of a third party or post any personal data
or other information about a third party, without the express consent of that
third party;</li>
<li>not to use another User's account or URL without permission, impersonate any
person or entity, falsely state or otherwise misrepresent your affiliation with
a person or entity, misrepresent a Charity or Campaign through the Service, or
post User Content in any inappropriate category or areas on the Service;</li>
<li>not create any liability for SpendLocal or cause us to lose (in whole or in
part) the Service of our Internet Service Provider(s), web hosting company or
any other vendors or suppliers;</li>
<li>not to engage in any conduct that, in SpendLocal’s sole judgment and
discretion, restricts or inhibits any other user from using or enjoying the
Service;</li>
<li>not to interfere with or disrupt any servers or networks used to provide the
Service or their respective features, or disobey any requirements of the
networks SpendLocal uses to provide the Service;</li>
<li>not to gain unauthorized access to the Service, or any account, computer
system, or network connected to these Service, by any unauthorized or illegal
means;</li>
<li>not to obtain or attempt to obtain any materials or information not
intentionally made available through the Service;</li>
<li>not to use the Service to post, transmit or in any way exploit any
information, software or other material for commercial purposes, or that
contains advertising, except that using the Service for fundraising activities
in accordance with these Terms is expressly permitted;</li>
<li>transmit more request messages through the Service in a given period of time
than a human can reasonably produce in the same period by using a conventional
online web browser;</li>
<li>undertake any activity or engage in any conduct that is inconsistent with
the business or purpose of the Service; or</li>
<li>attempt to undertake indirectly any of the foregoing.</li>

</ol>
<p>
Additionally, with respect to all Pledges you make or accept through the
Service, you agree:
</p>
<ol>
<li>not to make or accept any Pledges that you know or suspect to be erroneous,
suspicious or fraudulent;</li>
<li>not to use the Service in or for the benefit of a country, organization,
entity, or person embargoed or blocked by any government, including those on
sanctions lists identified by the United States Office of Foreign Asset Control
(OFAC);</li>
<li>to maintain reasonable and standard security measures to protect any
information transmitted and received through the Service, including without
limitation by adhering to any security procedures and controls required by
SpendLocal from time to time;</li>
<li>to maintain a copy of all electronic and other records related to Campaigns
and Pledges as necessary for SpendLocal to verify compliance with these Terms of
Service and make such records available to SpendLocal upon our request. For
clarity, the foregoing does not affect or limit your obligations to maintain
documentation as required by applicable laws, rules, regulations, or
governmental authority; and
<li>at SpendLocal’s request, including without limitation in case of
investigations by SpendLocal, a payment processing partner, or a regulatory or
governmental authority, to fully cooperate in the auditing of such records,
investigation of the relevant circumstances and remedy of any uncovered
violation or wrongdoing.</li>
</li>
</ol>
<p>
SpendLocal reserves the right to refuse, condition, or suspend any Pledges or
other transactions that we believe in our sole discretion may violate the Terms
of Service or harm the interests of our users, business partners, the public, or
SpendLocal, or that expose you, SpendLocal, or others to risks unacceptable to
us. We may share any information related to your use of the Service with the
appropriate financial institution, regulatory authority, or law enforcement
agency consistent with our legal obligations. This information may include
information about you, your account, your Pledgers, your Pledges, and
transactions made through or in connection with your use of the Service.
</p>
<p>
<strong>Pledgers Conduct</strong>
</p>
<p>
<strong>Pledges:</strong> In order to contribute to a Campaign or to an entity,
a Pledgers will be required to provide SpendLocal information regarding its
credit card or other payment instrument (“Payment Instrument”) that is linked to
the Pledgers’s account on the Service (a “Billing Account”). You, as a Pledgers,
represent and warrant to SpendLocal that such information is true and that you
are authorized to use the applicable Payment Instrument. You agree that a
certain minimum Pledge amount may apply, and that all Pledges are final and will
not be refunded unless SpendLocal, in its sole discretion, agrees to issue a
refund. SpendLocal uses third-party payment processing partners to bill you
through your Payment Instrument and Billing Account for any Pledges made, and
Pledgers acknowledge that by contributing a Pledge to a Campaign, the Pledgers
agrees to the processing, use, transfer or disclosure of data by our payment
processors pursuant to any and all applicable terms set forth by our payment
partners (currently, Stripe, Inc., and PayPal, Inc.,), in addition to these
Terms of Service, including<a href="https://stripe.com/us/legal"> Stripe’s terms
of service</a>, and <a
href="https://www.paypal.com/ga/webapps/mpp/ua/legalhub-full">PayPal’s terms of
service</a>
</p>
<p>
<strong>Recurring Pledges:</strong> Pledgers may have the option to contribute
recurring period Pledges (your agreement to make the Pledges on a recurring
basis, a “Pledge Subscription” and each individual Pledge made in connection
with a Pledge Subscription, a “Pledge Installment”), and in electing to
contribute on a recurring basis, you, as a Pledger hereby acknowledge that
Pledge Subscriptions automatically renew and have a recurring payment feature,
and that unless and until you opt out of the auto-renewal of the Pledge
Subscription, which can be done through the Platform, any Pledge Subscriptions
you have signed up for will be automatically extended for successive renewal
periods of the same duration as the initial term originally selected. In
connection with each of your Pledge Subscriptions, you (i) hereby authorize
SpendLocal to bill your Payment Instrument in the amount of the applicable
Pledge Installments in advance on a periodic basis until you terminate such
periodic payments by opting out of the Pledge Subscription, (ii) accept
responsibility for payment of all Pledge Installments occurring prior to opt out
and (iii) agree to promptly update your Billing Account with any changes (for
example, any changes related to your Payment Instrument, such as a change in
your billing address or credit card expiration date). Changes to or termination
of Pledge Subscriptions or Pledge Installments will apply only to Pledge
Installments that take place after SpendLocal receives notice of such change or
termination. SpendLocal does not provide refunds of any amounts received in
connection with previously made Pledge Installments. Additionally, by enrolling
in any Pledge Subscriptions, you acknowledge and agree for any and all such
Pledge Subscriptions, that (a) the ongoing maintenance and operation of Pledge
Subscriptions and each Pledge Installment are the sole responsibility of, and
subject to the sole discretion of, the individual or entity responsible for
managing and receiving the Pledge Subscription (e.g., the applicable Campaign
Organizer or entity), (b) individual Pledge Installments may not be tax
deductible, even if previous Pledge Installments for the same Pledge
Subscription were, and the amount of each Pledge Installment that is tax
deductible may vary, (c) one or more specific Pledge Installment may not be
provided to or received by the applicable cause, charity or Campaign if such
cause, charity or Campaign becomes unavailable, unable to accept Pledge support,
or chooses to stop receiving Pledges, which may occur for various reasons, such
as if the cause, charity or Campaign becomes subject to an investigation or is
suspended or removed from the Service by SpendLocal or (d) the amounts actually
received by the applicable Campaign, Charity or cause may differ from one Pledge
Installment to the next (for example, if the Payment Processor fees associated
with the Pledge Installment change).
</p>
<p>
Your non-termination of a Pledge Subscription reaffirms that SpendLocal is
authorized to char ge your Payment Instrument for the Pledge Subscription in
accordance with these terms. This does not waive our right to seek payment
directly from you.
</p>
<p>
<strong>Campaign Organizers or Beneficiaries </strong>
</p>
<p>
<strong>Account Holds:</strong> From time to time, SpendLocal may, in its sole
discretion, place a hold on a Campaign account (a "Hold"), restricting
Withdrawals (defined herein), initiate a reverse ACH transfer, secure reserves,
or take similar actions to protect its interests and those of its Users. Some of
the reasons that we may take such actions include, but aren’t necessarily
limited to, the following: (i) if we have reason to believe (in our sole
discretion) that information provided by a Campaign Organizer is false,
misleading, or fraudulent, or that funds are being used in a prohibited manner,
(ii) if the funds available (as determined by SpendLocal in its sole discretion)
should be provided directly to a person other than the Campaign Organizer (such
as a legal beneficiary or person entitled by law to act on behalf of a Campaign
Organizer), (iii) if we have reason to believe that a Campaign or Campaign
Organizer has violated these Terms of Service, (iv) if SpendLocal determines
that the Campaign Organizer is colluding with Pledgers to engage in fraudulent
activity, (v) if we have reason to believe (in our sole discretion) that there
may be suspicious or fraudulent Pledge activity, or (vi) if required in order to
comply with a court order, subpoena, writ, injunction, or as otherwise required
under applicable laws and regulations. If you have questions about a Hold we may
have placed on your Campaign account, or need information about how to resolve
the Hold, please message contact@gospendlocal.com.
</p>
<p>
<strong>Withdrawing Pledges from a Campaign:</strong> While SpendLocal strives
to make Withdrawals available to you promptly, you acknowledge and agree that
Withdrawals may not be available to you for use immediately, and SpendLocal does
not guarantee that Withdrawals will be available to you within any specific time
frame, and SpendLocal expressly disclaims any and all responsibility for any
delay or inability to access and use Withdrawals at any specified time, and any
consequences arising from such delay or inability. You, as a Campaign Organizer,
are responsible for ensuring that the information you provide to SpendLocal in
order to process a Withdrawal, including your bank account information, is
accurate and up to date. SpendLocal may, at any time, for any reason, without
notice, and in its sole discretion, offer or issue a refund of Pledge(s) with or
without consulting with you, which may comprise the entire amount Pledged to
your campaign. SpendLocal is not liable to you or to any third party for any
claims, damages, costs, losses, or other consequences caused by SpendLocal
issuing refunds, including, but not limited to transaction or overdraft fees.
</p>
<p>
<strong>Special Notice for International Use; Export Controls</strong>
</p>
<p>
Software (defined below) available in connection with the Service and the
transmission of applicable data, if any, may be subject to export controls and
economic sanctions laws of the United States or other jurisdictions. No Software
may be downloaded from the Service or otherwise exported or re-exported in
violation of such export control and economic sanctions laws. Downloading or
using the Software is at your sole risk. Recognizing the global nature of the
Internet, you agree to comply with all local rules and laws regarding your use
of the Service, including as it concerns online conduct and acceptable content.
</p>
<p>
Intellectual Property Rights
</p>
<p>
<strong>Service Content, Software and Trademarks:</strong> You acknowledge and
agree that the Service may contain content or features ("Service Content") that
are protected by copyright, patent, trademark, trade secret or other proprietary
rights and laws. Except as expressly authorized by SpendLocal, you agree not to
modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create
derivative works based on the Service, the Service Content, or Content, in whole
or in part, except that the foregoing does not apply to your own User Content
that you legally upload to the Service. In connection with your use of the
Service you will not engage in or use any data mining, spiders, robots, scraping
or similar data gathering or extraction methods. If you are blocked by
SpendLocal from accessing the Service (including by blocking your IP address),
you agree not to implement any measures to circumvent such blocking (e.g., by
masking your IP address or using a proxy IP address). Any use of the Service or
the Service Content other than as specifically authorized herein is strictly
prohibited. The technology and software underlying the Service or distributed in
connection therewith are the property of SpendLocal, our affiliates and our
partners (the "Software"). You agree not to copy, modify, create a derivative
work of, reverse engineer, reverse assemble or otherwise attempt to discover any
source code, sell, assign, sublicense, or otherwise transfer any right in the
Software. Any rights not expressly granted herein are reserved by SpendLocal.
</p>
<p>
The SpendLocal name and logos are trademarks and service marks of SpendLocal
(collectively the "SpendLocal Trademarks"). Other company, product, and service
names and logos used and displayed via the Service may be trademarks or service
marks of their respective owners, who may or may not endorse or be affiliated
with or connected to SpendLocal. Nothing in these Terms of Service or the
Service should be construed as granting, by implication, estoppel, or otherwise,
any license or right to use any of SpendLocal Trademarks displayed on the
Service, without our prior written permission in each instance. All goodwill
generated from the use of SpendLocal Trademarks will insure to our exclusive
benefit.
</p>
<p>
<strong>Third-Party Material:</strong> Under no circumstances will SpendLocal be
liable in any way for any content or materials of any third parties (including
Users) or any User Content (including, but not limited to, for any errors or
omissions in any User Content), or for any loss or damage of any kind incurred
as a result of the use of any such User Content. You acknowledge that SpendLocal
does not pre-screen User Content, but that SpendLocal and its designees will
have the right (but not the obligation) in their sole discretion to refuse,
remove, or allow any User Content that is available via the Service at any time
and for any reason, with or without notice, and without any liability to you or
to any third party for any claims, damages, costs or losses resulting therefrom.
</p>
<p>
<strong>User Content Transmitted Through the Service:</strong> With respect to
the User Content, you represent and warrant that you own all right, title and
interest in and to, or otherwise have all necessary rights and consents to (and
to allow others to) fully exploit, such User Content, including, without
limitation, as it concerns all copyrights, trademark rights and rights of
publicity or privacy related thereto. By uploading, sharing, providing, or
otherwise making available any User Content, or any portion thereof, in
connection with the Service, you hereby grant and will grant SpendLocal and its
affiliated companies and users a nonexclusive, worldwide, royalty free, fully
paid up, transferable, sublicensable, perpetual, irrevocable license to copy,
display, upload, perform, distribute, store, modify and otherwise use your User
Content in connection with the operation of the Service or the promotion,
advertising or marketing thereof, in any form, medium or technology now known or
later developed. Without limiting the foregoing, if any User Content contains
your name, image or likeness, you hereby release and hold harmless SpendLocal
and its contractors and employees, from (i) all claims for invasion of privacy,
publicity or libel, (ii) any liability or other claims by virtue of any
blurring, distortion, alteration, optical illusion, or other use or exploitation
of your name, image or likeness, and (iii) any liability for claims made by you
(or any successor to any claim you might bring) in connection with your User
Content, name, image or likeness. You waive any right to inspect or approve any
intermediary version(s) or finished version(s) of the results of the use of your
User Content (including your name, image or likeness). Further, if any person
(other than you) appears in your User Content, you represent and warrant that
you have secured all necessary licenses, waivers and releases from such
person(s) for the benefit of SpendLocal in a manner fully consistent with the
licenses, waivers and releases set forth above. You further acknowledge that
your participation in the Service and submission of User Content is voluntary
and that you will not receive financial compensation of any type associated with
the licenses, waivers, and releases set forth herein (or SpendLocal's
exploitation thereof), and that the sole consideration for subject matter of
this agreement is the opportunity to use the Service.
</p>
<p>
We do not guarantee that any Service Content will be made available through the
Service. We reserve the right to, but do not have any obligation to, (i) remove,
edit or modify any Service Content or User Content, in our sole discretion, at
any time, without notice to you and for any reason (including, but not limited
to, upon receipt of claims or allegations from third parties or authorities
relating to such Service Content or User Content, or if we are concerned that
you may have violated these Terms of Service), or for no reason at all and (ii)
to remove or block any Service Content or User Content from the Service.
</p>
<p>
<strong>Payment Card Industry Data Security Standard:</strong> The Payment Card
Industry Data Security Standard (PCI DSS) is a set of industry-mandated
requirements for any business that handles, processes, or stores credit cards.
The primary purpose of the standards is to maintain controls around cardholder
data to reduce credit card fraud. As a service provider, SpendLocal is PCI DSS
compliant and will maintain all applicable PCI DSS requirements to the extent
that we possess or otherwise store, process, or transmit cardholder data on
behalf of you, or to the extent that we can in any way impact the security of
your cardholder data environment.
</p>
<p>
<strong>Copyright Complaints:</strong> SpendLocal respects the intellectual
property of others, and we ask our users to do the same. If you believe that
your work has been copied in a way that constitutes copyright infringement, or
that your intellectual property rights have been otherwise violated, you should
notify SpendLocal of your infringement claim in accordance with the procedure
set forth below.
</p>
<p>
SpendLocal will process and investigate notices of alleged infringement and will
take appropriate actions under the Digital Millennium Copyright Act ("DMCA") and
other applicable intellectual property laws with respect to any alleged or
actual infringement. A notification of claimed copyright infringement should be
emailed to SpendLocal's Copyright Agent at legal@GoSpendLocal.com (Subject line:
"DMCA Takedown Request"). You may also contact us by mail or facsimile at:
</p>
<p>
SpendLocal Copyright Agent:
</p>
<p>
SpendLocal LLC.
</p>
<p>
169 Commack Rd,
</p>
<p>
suite H #381,
</p>
<p>
Commack, NY 11725
</p>
<p>
To be effective, the notification must be in writing and contain the following
information:
</p>
<ul>
<li>an electronic or physical signature of the person authorized to act on
behalf of the owner of the copyright or other intellectual property interest;</li>
<li>a description of the copyrighted work or other intellectual property that
you claim has been infringed;</li>
<li>a description of where the material that you claim is infringing is located
on the Service, with enough detail that we may find it on the Service;</li>
<li>your address, telephone number, and email address;</li>
<li>a statement by you that you have a good faith belief that the disputed use
is not authorized by the copyright or intellectual property owner, its agent, or
the law; and
<li>a statement by you, made under penalty of perjury, that the above
information in your notice is accurate and that you are the copyright or
intellectual property owner or authorized to act on the copyright or
intellectual property owner's behalf.</li>
</li>
</ul>
<p>
<strong>Counter-Notice:</strong> If you believe that your User Content that was
removed (or to which access was disabled) is not infringing, or that you have
the authorization from the copyright owner, the copyright owner's agent, or
pursuant to the law, to upload and use the content in your User Content, you may
send a written counter-notice containing the following information to the
above-listed Copyright Agent:
</p>
<ul>
<li>your physical or electronic signature;</li>
<li>identification of the content that has been removed or to which access has
been disabled and the location at which the content appeared before it was
removed or disabled;</li>
<li>a statement that you have a good-faith belief that the content was removed
or disabled as a result of mistake or a misidentification of the content; and
<li>your name, address, telephone number, and email address, and a statement
that you will accept service of process from the person who provided
notification of the alleged infringement.
</li></li>
</ul>
<p>
If a counter-notice is received by the Copyright Agent, SpendLocal will send a
copy of the counter-notice to the original complaining party, informing that
person that it may replace the removed content or cease disabling it in 10
business days. Unless the copyright owner files an action seeking a court order
against the content provider, member or user, the removed content may be
replaced, or access to it restored, in 10 to 14 business days or more after
receipt of the counter-notice, at our sole discretion.
</p>
<p>
<strong>Repeat Infringer Policy:</strong> In accordance with the DMCA and other
applicable law, SpendLocal has adopted a policy of terminating, in appropriate
circumstances and at SpendLocal's sole discretion, users who are deemed to be
repeat infringers. SpendLocal may also at its sole discretion limit access to or
terminate the Service and/or terminate the memberships of any users who infringe
any intellectual property rights of others, whether or not there is any repeat
infringement.
</p>
<p>
Third Party Websites/Service
</p>
<p>
The Service or third parties may provide or facilitate links, tools, widgets or
other features that allow you to access other sites, Service and resources
provided by third parties (collectively, “Third Party Resources”). SpendLocal
has no control over such Third Party Resources or any products, Service or
content made available through or by such Third Party Resources, or the business
practices of the third parties providing such Third Party Resources, and
SpendLocal is not responsible for and does not endorse such Third Party
Resources or the products, Service or content made available thereby. You
acknowledge that SpendLocal is not responsible or liable for the content,
functions, accuracy, legality, appropriateness or any other aspect of such Third
Party Resources. You further acknowledge and agree that SpendLocal will not be
responsible or liable, directly or indirectly, for any damage or loss caused or
alleged to be caused by or in connection with the use of or reliance on any
content, events, goods or Service available on or through any such Third Party
Resources. Any dealings you have with third parties found while using the
Service are between you and the third party and may be subject to additional
terms provided by the third party, which you agree to by using such Third Party
Resources. As an example of this, if you use the SpendLocal Platform through
your mobile device, and you upload a video to a fundraiser, that video may be
uploaded using YouTube, and subject to the YouTube<a
href="https://www.youtube.com/static?gl=GB&template=terms"> Terms of
Service</a>. And you agree that SpendLocal is not liable for any loss or claim
that you may have against any such third party.
</p>
<p>
Indemnity and Release
</p>
<p>
You agree to release, indemnify on demand and hold SpendLocal and its affiliates
and their officers, employees, directors and agents harmless from any and all
losses, damages, expenses, including reasonable attorneys' fees, costs, awards,
fines, damages, rights, claims, actions of any kind and injury (including death)
arising out of or relating to your use of the Service, any Pledge or Campaign,
any User Content, your connection to the Service, your violation of these Terms
of Service or your violation of any rights of another. You agree that SpendLocal
has the right to conduct its own defense of any claims at its own discretion,
and that you will indemnify SpendLocal for the costs of its defense (including,
but not limited to attorney’s fees.) If you are a California resident, you waive
California Civil Code Section 1542, which says: "A general release does not
extend to claims that the creditor or releasing party does not know or suspect
to exist in his or her favor at the time of executing the release, and that if
known by him or her would have materially affected his or her settlement with
the debtor or released party." If you are a resident of another jurisdiction—in
or outside of the United States—you waive any comparable statute or doctrine.
</p>
<p>
Disclaimer of Warranties
</p>
<p>
YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE ARE PROVIDED ON AN "AS
IS" AND "AS AVAILABLE" BASIS. SPENDLOCAL AND ITS AFFILIATES EXPRESSLY DISCLAIM
AND EXCLUDE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ALL WARRANTIES,
CONDITIONS AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR
STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
</p>
<p>
SPENDLOCAL AND ITS AFFILIATES MAKE NO WARRANTY OR CONDITION THAT (I) THE SERVICE
WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF
THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS,
SERVICE, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE
SERVICE WILL MEET YOUR EXPECTATIONS.
</p>
<p>
Limitation of Liability
</p>
<p>
YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY
APPLICABLE LAW, NEITHER SPENDLOCAL NOR ITS AFFILIATES WILL BE LIABLE FOR ANY (A)
INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, (B)
DAMAGES FOR LOSS OF PROFITS, (C) DAMAGES FOR LOSS OF GOODWILL, (D) DAMAGES FOR
LOSS OF USE, (E) LOSS OR CORRUPTION OF DATA, OR (F) OTHER INTANGIBLE LOSSES
(EVEN IF SPENDLOCAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
RESULTING FROM (I) THE USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST OF
PROCUREMENT OF SUBSTITUTE GOODS AND SERVICE RESULTING FROM ANY GOODS, DATA,
INFORMATION OR SERVICE PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR
TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III) ANY PROMOTIONS AND
RELATED PRIZES OR REWARDS MADE AVAILABLE THROUGH THE SERVICE; (IV) UNAUTHORIZED
ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (V) STATEMENTS OR CONDUCT
OF ANY THIRD PARTY ON THE SERVICE; OR (VI) ANY OTHER MATTER RELATING TO THE
SERVICE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
SPENDLOCAL'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES (INCLUDING CONTRACT,
NEGLIGENCE, STATUTORY LIABILITY OR OTHERWISE) OR CAUSES OF ACTION EXCEED THE
AMOUNT YOU HAVE PAID SPENDLOCAL IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE
HUNDRED DOLLARS ($100).
</p>
<p>
SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
ACCORDINGLY, SOME OF THE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF
YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF
SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
</p>
<p>
DISPUTES
</p>
<p>
ARBITRATION CLAUSE & CLASS ACTION WAIVER – IMPORTANT – PLEASE REVIEW AS THIS
AFFECTS YOUR LEGAL RIGHTS
</p>
<p>
<strong>Arbitration; Class Action Waiver.</strong> YOU AGREE THAT ALL DISPUTES
BETWEEN YOU AND US OR ANY OF OUR OFFICERS, DIRECTORS OR EMPLOYEES ACTING IN
THEIR CAPACITY AS SUCH (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD PARTY) WITH
REGARD TO YOUR RELATIONSHIP WITH US, INCLUDING WITHOUT LIMITATION DISPUTES
RELATED TO THESE TERMS OF SERVICE, YOUR USE OF THE SERVICE, AND/OR RIGHTS OF
PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION
AND YOU AND WE HEREBY EXPRESSLY WAIVE TRIAL BY JURY. DISCOVERY AND RIGHTS TO
APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A LAWSUIT, AND OTHER
RIGHTS THAT YOU AND WE WOULD HAVE IN COURT MAY NOT BE AVAILABLE IN ARBITRATION.
YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND WE ARE EACH
WAIVING OUR RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
</p>
<p>
Notwithstanding the foregoing, nothing in these Terms of Service will be deemed
to waive, preclude, or otherwise limit the right of either of us to (a) bring an
individual action in small claims court; (b) pursue an enforcement action
through the applicable federal, state, or local agency if that action is
available; (c) seek injunctive relief in a court of law; or (d) file suit in a
court of law to address an intellectual property infringement claim.
</p>
<ol>
<li><strong>No Class Actions.</strong> YOU AND WE AGREE THAT EACH MAY BRING
CLAIMS TO THE FULLEST EXTENT LEGALLY PERMISSIBLE AGAINST THE OTHER ONLY IN YOUR
OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and we
agree otherwise, the arbitrator may not consolidate more than one person’s
claims and may not otherwise preside over any form of a representative or class
proceeding.</li>
<li><strong>Modifications to this Arbitration Provision.</strong> If we make any
future change to this arbitration provision, other than a change to our address
for Notice of Arbitration, you may reject the change by sending us written
notice within 30 days of the change to our address for Notice of Arbitration, in
which case your account with us will be immediately terminated and this
arbitration provision, as in effect immediately prior to the changes you
rejected will survive.</li>
<li><strong>Enforceability.</strong> If this Disputes section is found to be
unenforceable, then the entirety of this Disputes section will be null and void
and, in that case, the parties agree that the exclusive jurisdiction and venue
described in the General section below will govern any action arising out of or
related to these Terms of Service.
</li>
</ol>
<p>
<strong>Confidentiality.</strong> We each agree to keep the arbitration
proceedings, all information exchanged between us, and any settlement offers
confidential, unless otherwise required by law or requested by law enforcement
or any court or governmental body. However, we may each disclose these matters,
in confidence, to our respective accountants, auditors, and insurance providers.
</p>
<p>
Termination
</p>
<p>
You agree that SpendLocal, in its sole discretion, may suspend or terminate your
account (or any part thereof) or your access to the Service and remove and
discard any User Content or data at any time and for any reason, with or without
notice, and without any liability to you or to any third party for any claims,
damages, costs or losses resulting therefrom.
</p>
<p>
User Disputes
</p>
<p>
You agree that you are solely responsible for your interactions with any other
user in connection with the Service and SpendLocal will have no liability or
responsibility with respect thereto. SpendLocal reserves the right, but has no
obligation, to become involved in any way with disputes between you and any
other user of the Service.
</p>
<p>
General
</p>
<p>
These Terms of Service constitute the entire agreement between you and
SpendLocal and govern your use of the Service, superseding any prior agreements
between you and SpendLocal with respect to the Service. You also may be subject
to additional terms of service that may apply when you use affiliate or
third-party Service, third-party content or third-party software. These Terms of
Service will be governed by the laws of the State of New York without regard to
its conflict of law provisions. With respect to any disputes or claims not
subject to arbitration, as set forth above, you and SpendLocal agree to submit
to the personal and exclusive jurisdiction of the state and federal courts
located within Suffolk County, New York. The failure of SpendLocal to exercise
or enforce any right or provision of these Terms of Service will not constitute
a waiver of such right or provision. If any provision of these Terms of Service
is found by a court of competent jurisdiction to be (or are otherwise) invalid,
the parties nevertheless agree that the court should endeavor to give effect to
the parties’ intentions as reflected in the provision, and the other provisions
of these Terms of Service remain in full force and effect. You agree that
regardless of any statute or law to the contrary, any claim or cause of action
arising out of or related to use of the Service or these Terms of Service must
be filed within one (1) year after such claim or cause of action arose or be
forever barred. A printed version of this agreement and of any notice given in
electronic form will be admissible in judicial or administrative proceedings
based upon or relating to this agreement to the same extent and subject to the
same conditions as other business documents and records originally generated and
maintained in printed form. You may not assign these Terms of Service without
the prior written consent of SpendLocal, but SpendLocal may assign or transfer
these Terms of Service, in whole or in part, without restriction. If we fail to
enforce any of our rights, that does not result in a waiver of that right. The
section titles in these Terms of Service are for convenience only and have no
legal or contractual effect. Notices to you may be made via either email or
regular mail. The Service may also provide notices to you of changes to these
Terms of Service or other matters by displaying notices or links to notices
generally on the Platform. SpendLocal may, at any time, assign our rights or
delegate our obligations hereunder without notice to you in connection with a
merger, acquisition, reorganization or sale of equity or assets, or by operation
of law or otherwise. Nothing in these Terms shall prevent SpendLocal from
complying with the law. SpendLocal shall not be liable for any delay or failure
to perform resulting from causes outside its reasonable control, including, but
not limited to, acts of God, Pandemics, war, terrorism, riots, embargos, acts of
civil or military authorities, fire, floods, accidents, strikes or shortages of
transportation facilities, fuel, energy, labor or materials.
</p>
<p>
Privacy Policy
</p>
<p>
At SpendLocal, we respect the privacy of our users. For details please see our<a
href="https://www.gospendlocal.com/privacy"> Privacy Policy</a>. By using the
Service, you consent to our collection and use of personal data as outlined
therein.
</p>
<p>
Questions? Concerns? Suggestions?
</p>
<p>
Please <a href="mailto:support@gospendlocal.com">contact us</a> to<a
href="https://support.gofundme.com/hc/articles/203604694-Reporting-a-Campaign">
</a>report violation or pose any question.
</p>
        </div>
    )
}
import React from 'react';

import SLButton from '../components/SLButton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import addBusinessSVG from '../resources/addBusiness.svg';
import { Link } from 'react-router-dom';

import '../styles/_addbusinessmarker.css';
import SLLink from './SLLink';

class AddBusinessMarker extends React.Component {

    renderAddBusinessMarker = () => {
        return (
            <div className="add-business-marker-container">
                <SLButton
                    trackingInfo={{name: "START_ADD_BUSINESS"}} 
                    onClick={this.props.onClick || (() => {})} 
                    className="add-new-business-container">
                    <img className="add-business-marker-add-image" alt="gray cross in a gray circle" src={addBusinessSVG} />
                    <span className="add-business-marker-add-text">Add New Business</span>
                </SLButton>
            </div>
        )
    }

    renderBusinessMarker = () => {
        return (
            <div className="add-business-marker-container">
                <img className="add-business-marker-image" alt="business thumbnail" src={this.props.business.thumbnailImage} />
                <div className="add-business-marker-info">
                    <span className="add-business-marker-name">{this.props.business.name || "Business Name"}</span>
                    
                    <div className="add-business-marker-manage-container">
                        <SLButton
                          trackingInfo={{name: "BEGIN_EDIT_BUSINESS", info: {businessLink: this.props.viewLink}}}
                          onClick={this.props.manage || (() => {})} 
                          className="add-business-marker-manage-button">
                          <EditIcon /> Edit
                        </SLButton>

                        <SLLink
                            to={this.props.viewLink}
                            trackingInfo={{name: "VIEW_BUSINESS", info: {businessLink: this.props.viewLink}}}
                            className="add-business-marker-manage-button">
                            <VisibilityIcon /> View
                        </SLLink>
                    </div>

                </div>
            </div>
        )
    }

    render () {

        if (this.props.business) {
            return this.renderBusinessMarker();
        } else {
            return this.renderAddBusinessMarker();
        }

    }
}

export default AddBusinessMarker;
import React from 'react';

import "../styles/_userprofiletag.css";

var classNames = require('classnames');

class UserProfileTag extends React.Component {

  render () {
    return (
      <div className={classNames("user-profile-tag")}>
        {
          this.props.color 
            && 
          <div className="user-profile-tag-color" style={{"backgroundColor": this.props.color, "opacity": "50%"}} />
        }

        {
          <div className="user-profile-tag-number">{this.props.number}</div>
        }
          {this.props.children}
      </div>
    )
  }
}

export default UserProfileTag
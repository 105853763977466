import { 
    SET_ERRORS, 
    CLEAR_ERRORS, 
    LOADING_UI,
    STOP_LOADING_UI,
    LOADING_UI_ELEMENT,
    STOP_LOADING_UI_ELEMENT,
    STOP_LOADING_UI_ELEMENTS,
} from '../types';

const initialState = {
    loading: false, 
    errors: {},
    message: {},
    elementsLoading:{}
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_ERRORS:
            console.log("Error Payload:", action.payload);
            window.analytics.track("UI: " + SET_ERRORS, {
                errors: action.payload,
            });
            return {
                ...state,
                loading: false,
                errors: action.payload,
            };
        case CLEAR_ERRORS:
            // window.analytics.track("UI:" + CLEAR_ERRORS);
            return {
                ...state,
                loading: false,
                errors: {},
            };
        case LOADING_UI:
            // window.analytics.track("UI:" + LOADING_UI)
            return {
                ...state,
                loading: true,
            };
        case STOP_LOADING_UI:
            // window.analytics.track("UI:" + STOP_LOADING_UI)
            return {
                ...state,
                loading: false,
                message: action.payload,
            };

        case LOADING_UI_ELEMENT:
            // window.analytics.track("UI:" + LOADING_UI_ELEMENT, {
            //     elementsLoading: state.elementsLoading,
            // });

            state.elementsLoading[action.payload] = true;
            return {
                ...state,
                elementsLoading: state.elementsLoading
            }
        case STOP_LOADING_UI_ELEMENT:
            // window.analytics.track("UI:" + STOP_LOADING_UI_ELEMENT, {
            //     elementStoppedLoading: action.payload,
            // });

            state.elementsLoading[action.payload] = false;
            return {
                ...state,
                elementsLoading: state.elementsLoading
            }
        case STOP_LOADING_UI_ELEMENTS:
            const elements = Object.keys(action.payload);
            // window.analytics.track("UI:" + STOP, {
            //     elementsStoppedLoading: elements,
            // })
            for (let key of elements) {
                state.elementsLoading[key] = false;
            }
            return {
                ...state,
                elementsLoading: state.elementsLoading,
            }
        default: 
            return state;
    }
}
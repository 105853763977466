import React, { useState, useEffect } from 'react';
import '../styles/_businesses.css';
import PageHeader from '../components/PageHeader'
import BusinessMarker from '../components/BusinessMarker'; 
import FeatureBlock from '../components/FeatureBlock'; 
import BusinessCarousel from '../components/BusinessCarousel';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getFeaturedBusinesses } from '../redux/actions/dataActions';

export default function Businesses (props) {
  let [modal, setModal] = useState('modal');

    // Redux state
    const data = useSelector(state => state.data);
    const featuredBusinesses = data.featuredBusinesses;
    const UI = useSelector(state => state.UI);
  
    const dispatch = useDispatch();
  
    useEffect(() => {
      if ((featuredBusinesses.length === 0) && (!UI.elementsLoading.featuredBusinesses)) {
        console.log("Loading")
        dispatch(getFeaturedBusinesses());
      }
    });

    let dummyBusinesses = {
      "Restaurants": [{}, {}, {}, {}, {}, {}], 
      "Hardware Stores": [{}, {}, {}, {},{}], 
      "General Goods": [{}, {}, {}, {}, {}, {}], 
      "Sports Shops": [{}, {}, {}, {}], 
    }

  return (
    <div className="page-body">
      <PageHeader 
        mainTitle="Support these Businesses!" 
        subTitle="Find a local business to support in your community!">
        {!props.isMobile && <div className="search-bar-placeholder"></div>}
      </PageHeader> 

      <BusinessCarousel title="Featured Business">
        <FeatureBlock
          isMobile={props.isMobile} 
          loading={UI.elementsLoading.featuredBusinesses} 
          business={featuredBusinesses[0]}>
          <BusinessMarker 
            loading={UI.elementsLoading.featuredBusinesses} 
            business={featuredBusinesses[0]}
            />
        </FeatureBlock>
      </BusinessCarousel>

      {
        Object.keys(dummyBusinesses).map(category => {
          return (
            <BusinessCarousel comingSoon={true} title={category}>
              {dummyBusinesses[category].map(business => {
                return ( 
                <BusinessMarker/>
                )
              })}
            </BusinessCarousel>
          )
        })
      }
    </div>
  );

}

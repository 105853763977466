import React from "react";
import "../styles/_userprofile.css";
import { Redirect } from "react-router-dom";
import UserProfileTag from "../components/UserProfileTag";
import BusinessCarousel from "../components/BusinessCarousel";
import AddBusinessMarker from "../components/AddBusinessMarker";
import SLButton from "../components/SLButton";

import ManageProfileModal from "../modals/ManageProfileModal";
import AddNewBusinessModal from "../modals/AddNewBusinessModal";
import UpdateBusinessInfoModal from "../modals/UpdateBusinessInfoModal";
import AddNewCauseModal from "../modals/AddNewCauseModal";
import EditIcon from "@material-ui/icons/Edit";
import LoadingPage from '../components/LoadingPage';

// Redux
import { connect } from "react-redux";


class UserProfile extends React.Component {
  state = {
    loading: false,
    modal: "",
    errors: {},
    newImage: null,
  };

  toggleModal = () => {
    this.setState({
      modal: "",
      businessToUpdate: undefined,
    });
  };

  getBusinessToUpdate = () => {
    let { user } = this.props;

    if (user.businesses) {
      let business = user.businesses.find(business => business.businessId === this.state.businessToUpdate);
      return business;
    }
  }

  render() {
    let { user, UI } = this.props;
    let { handle, imageUrl } = user.credentials;

    user.local = user.local || {numVisits: 0, numVolunteerHours: 0}

    if (!user || !user.credentials) {
      return <Redirect to="/login" />;
    }

    if (UI.elementsLoading.user) {
      return (<LoadingPage />)
    }

    let businessToUpdate = this.getBusinessToUpdate();
    return (
      <div className="page-body">
        <ManageProfileModal
          toggle={this.toggleModal}
          isOpen={this.state.modal === "manageProfile"}
        />
        <AddNewBusinessModal
          toggle={this.toggleModal}
          isOpen={this.state.modal === "addNewBusiness"}
        />
        <UpdateBusinessInfoModal
        addNewCause={() => {this.setState({modal: "addNewCause"})}}
          businessToUpdate={businessToUpdate}
          addCause={() => {this.setState({modal: 'addNewCause'})}}
          toggle={this.toggleModal}
          isOpen={this.state.modal === "updateBusinessInfo"}
        />
        <AddNewCauseModal
          businessToUpdate={businessToUpdate}
          isOpen={this.state.modal === "addNewCause"}
          toggle={() => this.setState({modal: "updateBusinessInfo"})} />

        <div className="user-profile-header">
          {/* Basic Profile Information */}
          <UserProfileTag>
            <img
              alt="user profile"
              className="user-profile-image"
              src={imageUrl}
            />
            <div className="user-profile-info">
              <div className="user-profile-name">{"@" + handle}</div>
              <div className="user-profile-manage-button-container">
                <SLButton 
                trackingInfo={{name: "MANAGE_PROFILE"}} 
                onClick={() => {this.setState({ modal: "manageProfile" })}} 
                className="user-profile-manage-button">
                  <EditIcon className="user-profile-edit-icon" /> Manage Profile
                </SLButton>
              </div>
            </div>
          </UserProfileTag>

          {/* Local Visits */}
          <UserProfileTag color="#FFC48F" number={user.local.numVisits || 0}>
            <div className="user-profile-tag-name">
              Local <br /> Visits
            </div>
          </UserProfileTag>

          {/* Volunteer Hours */}
          <UserProfileTag color="#56CCF2" number={user.local.numVolunteerHours || 0}>
            <div className="user-profile-tag-name">
              Volunteer <br /> Hours
            </div>
          </UserProfileTag>
        </div>
        <BusinessCarousel title="My Businesses">
          {user.businesses.map((business) => (
            <AddBusinessMarker
              manage={() => {
                this.setState({
                  modal: "updateBusinessInfo",
                  businessToUpdate: business.businessId,
                });
              }}
              viewLink={'/biz/' + business.businessId}
              key={business.id}
              business={business}
            />
          ))}
          <AddBusinessMarker
            onClick={() => {
              this.setState({ modal: "addNewBusiness" });
            }}
          />
        </BusinessCarousel>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, { })(
  UserProfile
);

import React from 'react';
import '../styles/_featureblock.css';
import BarLoader from 'react-spinners/BarLoader';
import SLLink from './SLLink';

let classNames = require('classnames');

export default function FeatureBlock (props) {


  function renderFeatureBlock () {
    return (
      <div 
      className={classNames("feature-block", "feature-block-short", {"feature-block-mobile": props.isMobile}, {"feature-block-mobile-container": (!props.business && props.isMobile)})}>
        {props.children}
        <div className="feature-title">
          {props.loading && <BarLoader size={30} color='#4C4C4C'/>}
          {props.business ? props.business.featureText : props.title}
        </div>
      </div>
    )
  }


  if (props.business) {
    let featuredBusiness = `/biz/${props.business.businessId}`;
    return (
      <SLLink 
      trackingInfo={{name: "VIEW_FEATURED_BUSINESS", info: {business: featuredBusiness}}}
      className={classNames("feature-link", {"feature-block-mobile-container": props.isMobile})} 
      to={featuredBusiness}>
        {renderFeatureBlock()}
      </SLLink>
    )
  } else {
    return renderFeatureBlock();
  }

}
import React from 'react';
import '../styles/_signin.css';
import '../styles/_login.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import LoadingPage from '../components/LoadingPage';

import SLButton from '../components/SLButton';

// Redux 
import { connect } from 'react-redux';
import { loginUser } from '../redux/actions/userActions';
import { Redirect } from 'react-router-dom';

import heroIllustration from '../resources/heroIllustration.svg';
import SLLink from '../components/SLLink';
var classNames = require('classnames');

const logInValidation = Yup.object().shape({
  email: Yup.string()
    .required('No email provided')
    .email('Must be a valid email.'),
  password: Yup.string()
    .required('No password provided')
    .min(8, 'Password must be at least 8 characters.')
    .max(50, 'Password cannot be longer than 50 characters'), 
})


class Login extends React.Component {
  state = {
    loading: false,
    errors: {},
  }

  defaultValues = {
    email: '',
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ 
        errors: nextProps.UI.errors.data || {},
        loading: nextProps.UI.loading,
      })
    }
  }

  handleLogIn = (userData) => {
    this.props.loginUser(userData, this.props.history);
  }

  renderForm = () => {
    const { UI } = this.props;


    const labelClassName = classNames("sign-in-form-label", {"mobile": this.props.isMobile});
    const inputClassName = classNames("sign-in-form-input", {"mobile": this.props.isMobile});
    const errorClassName = classNames("sign-in-form-error", {"mobile": this.props.isMobile});

    return (
      <Formik
        initialValues={this.defaultValues}
        validationSchema={logInValidation}
        onSubmit={(values, actions) => {
            this.handleLogIn(values);
            actions.setSubmitting(false);
        }}>
        <Form className={classNames("sign-in-form", {"mobile": this.props.isMobile})}>
            <label className={labelClassName} htmlFor="email">Email</label>
            <Field className={inputClassName} type="text" name="email" />
            <ErrorMessage className={errorClassName} name="email" component="div" />
            <label className={errorClassName} name="handle">{this.state.errors.email}</label>

            <label className={labelClassName} htmlFor="password">Password</label>
            <Field className={inputClassName} type="password" name="password" />
            <ErrorMessage className={errorClassName} name="password" component="div" />
            <label className={errorClassName} name="handle">{this.state.errors.password}</label>

            <div className={errorClassName} component="div">{this.state.errors && this.state.errors.general}</div>

            <div className="sign-in-reset-password">
              <SLLink className="sign-in-reset-link" to="/resetPassword">forgot password?</SLLink>
            </div>

            <SLButton
              trackingInfo={{name: "LOG_IN"}}
              type="submit" 
              className={classNames("sign-in-submit-button", {"mobile":this.props.isMobile})} 
              loading={this.state.loading || UI.elementsLoading.login}>Log In</SLButton>

            {
              <div className="sign-in-submit-separator">
                  Or
              </div>
            }

            <SLLink
              to="/signup"
              trackingInfo={{name: "CONVERT_TO_SIGNUP"}}
              type="button" 
              className={classNames("sign-in-submit-button", "sign-in-other-button", {"mobile":this.props.isMobile})}>
                Create Account
            </SLLink>
        </Form>
    </Formik>
    );
  }



  renderDesktop = () => {
    return (
      <div className="sign-in-body">
        <div className="sign-in-info">
            <div className="sign-in-info-text">
                <div className="sign-in-info-title">
                    Buy Local, Build Local
                </div>
                <div className="sign-in-info-subtitle">
                    Support local businesses while getting awesome deals!
                </div>
            </div>
            <img className="sign-in-hero-illustration" alt="an illustration of small business owners in front of signs and shops" src={heroIllustration} />
        </div>
        <div className="sign-in-form-container">
            <div className="sign-in-form-wrapper">
                <div className="sign-in-form-title">
                    Log In
                </div>
                <div className="sign-in-form-subtitle">
                    And Support Local Businesses!
                </div>
                {this.renderForm()}
            </div>
        </div>
    </div>
    )
  }

  renderMobile = () => {
    return (
      <div className="sign-in-body-mobile">
          <div className="sign-in-form-wrapper mobile">
              <div className="sign-in-form-title mobile">
                  Log In
              </div>
              {this.renderForm()}
          </div>
      </div>
    )
  }

  render () {
    const { user, UI } = this.props;
    const { authenticated } = user;
    if (authenticated) {
      return <Redirect to='/businesses' />
    }

    if (UI.elementsLoading.user) {
      return <LoadingPage />
    }

    return (
        (this.props.isMobile) ?
        this.renderMobile() :
        this.renderDesktop()
    )

  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
})

const mapActionsToProps = ({
  loginUser
})

export default connect(mapStateToProps, mapActionsToProps)(Login);

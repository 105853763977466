import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import { useDispatch, useSelector } from 'react-redux';
import { createPaymentIntent } from '../redux/actions/paymentActions';
import DollarChoice from '../components/DollarChoice';

import * as Yup from 'yup';

import '../styles/_checkoutform.css';

const checkoutValidationSchema = Yup.object().shape({
  cardholderEmail: Yup.string()
    .required('No email provided')
    .email('Must be a valid email.'),
  cardholderName: Yup.string()
    .required('Must provide full name')
    .min(2, 'Name must be at least 2 characters.')
})

export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [fetchingClientSecret, setFetchingClientSecret] = useState(false);
  const [sendingPayment, setSendingPayment] = useState(false);


  const [cardholderName, setCardholderName] = useState('');
  const [cardholderEmail, setCardholderEmail] = useState('');
  const [checkoutErrors, setCheckoutErrors] = useState({});
  
  const [chosenCost, setChosenCost] = useState(20);
  const stripe = useStripe();
  const elements = useElements();

  const payments = useSelector(state => state.payments);
  const UI = useSelector(state => state.UI);
  const dispatch = useDispatch();

  const setEmail = (email) => {
    setCardholderEmail(email)
    props.setCheckoutEmail && props.setCheckoutEmail(email);
  }
  const handlePaymentFailed = (error) => {
    setError(error);
    props.setPaymentError(error);
    setProcessing(false);
    props.setPaymentStatus && props.setPaymentStatus("failed");
  }

  const handlePaymentSuccess = (message) => {
    window.analytics.track("PAYMENT_SUCCESS")
    setSucceeded(true);
    setError(null);
    setProcessing(false);  
    props.setPaymentError(null);
    props.setPaymentStatus && props.setPaymentStatus("success");
  }

  let sendPaymentRequest = async () => {
    setSendingPayment(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: cardholderName,
          email: cardholderEmail,
        },
      }
    });

    if (payload.error) {
      handlePaymentFailed(`Payment failed ${payload.error.message}`);
    } else {
      if (payload.paymentIntent && payload.paymentIntent.status === 'succeeded') {
        handlePaymentSuccess();
      } else {
        handlePaymentFailed(`Payment Failed`);
      }
    }
  }

  useEffect(() => {

    // If we have a new clientSecret and it's not equal to the old one.
    if (payments.clientSecret !== '' && payments.clientSecret !== clientSecret) {
      setClientSecret(payments.clientSecret)
      setFetchingClientSecret(false);
    }


    // If we have a client secret, and we're attempting to process a payment but have not sent it yet...
    if (clientSecret !== '' && processing && !sendingPayment) {
      setSendingPayment(true);
      sendPaymentRequest();
    }

  }, [payments, clientSecret]);

  // TODO: Need style!
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    // Check if inputs are valid
    checkoutValidationSchema.validate({
      cardholderEmail: cardholderEmail,
      cardholderName: cardholderName
    })
    .then((valid) => {
      // Valid Form
      dispatch(createPaymentIntent({
        chosenCost: chosenCost, 
        cause: props.cause, 
        cardholderEmail:cardholderEmail,
        cardholderName:cardholderName,
        description: `Supporting ${props.business.name}'s cause ${props.cause.title}.`,
      }));
      setFetchingClientSecret(true);
    })
    .catch((err) =>{
      let errors = {};
      errors[err.path] = err.message;
      setCheckoutErrors(errors);
      setProcessing(false);
      window.analytics.track("PAYMENT_ERROR", {errors});
      return;
    });
  };

  let setCurrentCost = (cost) => {
    window.analytics.track("CHOOSE_COST", {cost})
    setChosenCost(cost);
    props.setCheckoutAmount && props.setCheckoutAmount(cost);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="payment-form-checkout-title">Checkout</div>
      <div className="checkout-choice-container">
        <div>
          <DollarChoice setChosenCost={setCurrentCost} chosenCost={chosenCost} cost={5} minBenefit={props.cause.benefitMinimum}/>
          <DollarChoice setChosenCost={setCurrentCost} chosenCost={chosenCost} cost={10} minBenefit={props.cause.benefitMinimum}/>
          <DollarChoice setChosenCost={setCurrentCost} chosenCost={chosenCost} cost={20} minBenefit={props.cause.benefitMinimum}/>
          <DollarChoice setChosenCost={setCurrentCost} chosenCost={chosenCost} cost={50} minBenefit={props.cause.benefitMinimum}/>
        </div>
        <div>
        <DollarChoice setChosenCost={setCurrentCost} chosenCost={chosenCost} cost={100} minBenefit={props.cause.benefitMinimum}/>
        <DollarChoice setChosenCost={setCurrentCost} chosenCost={chosenCost} cost={250} minBenefit={props.cause.benefitMinimum}/>
        </div>
   
      </div>
        <span className="checkout-subtitle">Cardholder Details</span>
        <div className="checkout-form-row">
          <input
          disabled={processing || fetchingClientSecret} 
          placeholder="Name" 
          className="payment-input" 
          name="cardholderName" 
          onChange={(ev) => setCardholderName(ev.target.value)} 
          value={cardholderName}/>
          <input 
          disabled={processing || fetchingClientSecret} 
          placeholder="Email" 
          className="payment-input" 
          name="cardholderEmail" 
          onChange={(ev) => setEmail(ev.target.value)} 
          value={cardholderEmail}/>
        </div> 
        <div className="checkout-form-row">
          <span className="checkout-form-error">{checkoutErrors.cardholderName}{checkoutErrors.cardholderEmail}</span>
        </div> 

        <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        <button
            onClick={() => window.analytics.track("SUBMIT_PAYMENT", {cost: chosenCost, email: cardholderEmail})}
            className="payment-button"
            disabled={processing || disabled || succeeded || fetchingClientSecret || cardholderName === '' || cardholderEmail === ''}
            id="submit"
        >
            <span id="button-text">
            {processing ? (
                <div className="spinner" id="spinner"></div>
            ) : (
                "Pay"
            )}
            </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
            <div className="card-error" role="alert">
            Error Processing Payment
            </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? "result-message" : "result-message hidden"}>
            Payment success!
        </p>
    </form>
  );
}

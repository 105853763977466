import React from 'react';

import addBusinessSVG from '../resources/addBusiness.svg';
import SLButton from './SLButton';

import '../styles/_addcausetag.css';

export default function AddCauseTag (props) {

  return (
    <div className="add-cause-tag-container">
      <SLButton
        trackingInfo={{name: "ADD_NEW_CAUSE"}}
        onClick={props.onClick} 
        className="add-cause-tag">
        <img 
          className="add-cause-tag-image" 
          alt="gray cross in a gray circle" 
          src={addBusinessSVG} />
        <span 
          className="add-cause-tag-text">
          Add New Cause
        </span>
      </SLButton>
    </div>
  )
}
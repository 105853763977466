import React from 'react';

function Shop() {
  return (
    <div className="App">
      <h1>Community Page</h1>
    </div>
  );
}

export default Shop;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router, BrowserRouter} from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

function initializeTracking () {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    window.analytics.load("L8t4Weqs3RjJ3rxymYMNbMRdcjJGQcgy");
  } else {
    // Production
    window.analytics.load("KWEA6TJBRINQHaAFumBsqPtTX2qGaC57");
  }
}

ReactDOM.render(
  <React.StrictMode>
    {initializeTracking()}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { 
    SET_USER, 
    SET_ERRORS, 
    CLEAR_ERRORS, 
    LOADING_UI, 
    SET_AUTHENTICATED, 
    SET_UNAUTHENTICATED, 
    LOADING_USER,
    CREATE_BUSINESS,
    EDIT_BUSINESS,
    LIKE_BUSINESS,
    UNLIKE_BUSINESS,
    CREATE_CAUSE,
    EDIT_CAUSE,
    DELETE_CAUSE
} from '../types';

const initialState = {
    authenticated: false,
    loading: false,
    credentials: {},
    businesses: [],
    likes: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_AUTHENTICATED:
            window.analytics.track("USER:" + SET_AUTHENTICATED);
            return {
                ...state,
                authenticated: true,
            }
        case SET_UNAUTHENTICATED:
            window.analytics.track("USER:" + SET_UNAUTHENTICATED);
            return initialState;
        case SET_USER:
            let creds = action.payload.credentials;
            let traits = {
                email: creds.email,
                uniqueHandle: creds.uniqueHandle,
                zipCode: creds.zipCode,
            }
            window.analytics.identify(creds.userId, traits)
            return {
                authenticated: true,
                loading: false,
                ...action.payload
            }
        case EDIT_BUSINESS:
            window.analytics.track("USER:" + EDIT_BUSINESS);
            // Get rid of the business pre-edit.
            let editedBusiness = state.businesses.find(business => business.businessId === action.payload.businessId);
            let cleanBusinesses = state.businesses.filter(business => business.businessId !== action.payload.businessId);
            
            let updatedBusiness = action.payload

            if (!action.payload.causes) {
                updatedBusiness.causes = editedBusiness.causes;
            }

            return {
                ...state,
                businesses: [...cleanBusinesses, updatedBusiness]
            }
        case LOADING_USER:
            window.analytics.track("USER:" + LOADING_USER);
            return {
                ...state, 
                loading: true,
            }
        case CREATE_BUSINESS:
            window.analytics.track("USER:" + CREATE_BUSINESS);
            return {
                ...state,
                businesses: [action.payload, ...state.businesses]
            }
        case CREATE_CAUSE:
            window.analytics.track("USER:" + CREATE_CAUSE);
            let cause = action.payload;
            let business = state.businesses.find(business => business.businessId === cause.businessId);
            // Add the cause to the businesses causes.
            if (business && Object.keys(business).length >0 && business.causes) {
                business.causes.push(cause);
                return {
                    ...state,
                    businesses: [...state.businesses.filter(business => business.businessId !== cause.businessId), business]
                }
            } else {
                return state;
            }
        case EDIT_CAUSE:
            window.analytics.track("USER:" + EDIT_CAUSE);
            let newCause = action.payload;

            let replaceCause = (business, updatedCause) => {
                let causes = [...business.causes.filter(cause => cause.causeId !== updatedCause.id), updatedCause];
                business.causes = causes;
                return business;
            }

            return {
                ...state,
                businesses: state.businesses.map(business => replaceCause(business, newCause)),
            }
        case DELETE_CAUSE:
            window.analytics.track("USER:" + DELETE_CAUSE);
                let causeId = action.payload;
    
                return {
                    ...state,
                    businesses: state.businesses.map(business => {
                        business.causes = business.causes.filter(cause => !cause.causeId === causeId)
                        return business;
                    }),
                }
        case LIKE_BUSINESS:
            window.analytics.track("USER:" + LIKE_BUSINESS);
            return {
                ...state,
                likes: [
                    ...state.likes,
                    {
                        uniqueHandle: state.credentials.uniqueHandle,
                        businessId: action.payload.businessId,
                    }
                ]
            };
        case UNLIKE_BUSINESS:
            window.analytics.track("USER:" + UNLIKE_BUSINESS);
            return {
                ...state,
                likes: state.likes.filter(
                    (like) => (like.businessId !== action.payload.businessId)
                )
            };
        default:
            return state;
    }
}
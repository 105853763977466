// User Reducer Types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';

// UI Reducer Types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const FAVORITE_BUSINESS = 'FAVORITE_BUSINESS';
export const UNFAVORITE_BUSINESS = 'UNFAVORITE_BUSINESS';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const LOADING_UI_ELEMENT = 'LOADING_UI_ELEMENT';
export const STOP_LOADING_UI_ELEMENT = 'STOP_LOADING_UI_ELEMENT';
export const STOP_LOADING_UI_ELEMENTS = 'STOP_LOADING_UI_ELEMENTS';

// Payment Types
export const CREATE_PAYMENT_INTENT = 'CREATE_PAYMENT_INTENT';

// Data Reducer Types
export const CREATE_BUSINESS = 'CREATE_BUSINESS';
export const EDIT_BUSINESS = 'EDIT_BUSINESS';
export const SET_BUSINESS = 'SET_BUSINESS';
export const SET_FEATURED_BUSINESSES = 'SET_FEATURED_BUSINESSES';
export const RESET_BUSINESS = 'RESET_BUSINESS';
export const LIKE_BUSINESS = 'LIKE_BUSINESS';
export const UNLIKE_BUSINESS = 'UNLIKE_BUSINESS';
export const CREATE_CAUSE = 'CREATE_CAUSE';
export const SET_CAUSE = 'SET_CAUSE';
export const EDIT_CAUSE = 'EDIT_CAUSE';
export const DELETE_CAUSE = 'DELETE_CAUSE';
import React from "react";

import FormTabs from "../components/FormTabs";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { businessCategories } from "../util/data";
import SpecialButton from "../components/SpecialButton";
import EditIcon from "@material-ui/icons/Edit";
import CauseTag from '../components/CauseTag';
import SLLink from '../components/SLLink';
import SLButton from '../components/SLButton';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import 'pretty-checkbox/dist/pretty-checkbox.min.css';

// Redux
import { connect } from "react-redux";
import {
  uploadBusinessThumbnailImage,
  uploadBusinessCoverImage,
  editBusinessDetails
} from "../redux/actions/userActions";

import "../styles/_updatebusinessinfomodal.css";
import AddCauseTag from "../components/AddCauseTag";

const formTypes = {
  Basic: "basic",
  Images: "images",
  Contact: "contact",
  Causes: "causes",
  Publish: "publish",
};

const basicBusinessInfoValidation = Yup.object().shape({
  name: Yup.string().max(200, "Must be under 200 characters."),
  nickName: Yup.string().max(100, "Must be under 100 characters."),
  category: Yup.string(),
  shortDescription: Yup.string().max(100, "Must be under 100 characters."),
  longDesctiption: Yup.string()
    .min(100, "Must be at least 100 characters.")
    .max(750, "Must be under 750 characters."),
  tags: Yup.array(),
  secondaryCategory: Yup.string().when("category", {
    is: "Other",
    then: Yup.string()
      .required("Must add a custom business category!")
      .min(4, "Must be at least 4 characters")
      .max(25, "Can't be more than 25 characters"),
  }),
});

const publishBusinessInfoValidation = Yup.object().shape({
  readyToPublish: Yup.boolean(),
})

const contactBusinessInfoValidation = Yup.object().shape({
  phone: Yup.string().matches(/^\d{10}$/, 'Must be a 10 digit phone number with no symbols!'),
  website: Yup.string().url("Must be a valid url."),
  address: Yup.string(),
  zipCode: Yup.string()
  .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Must be a valid 5 or 9 digit zip code."),
})


class UpdateBusinessInfoModal extends React.Component {
  state = {
    errors: null,
    formType: "basic",
    thumbnailImage: null,
    thumbnailImageSrc: null,
    coverImage: null,
    coverImageSrc: null,
  };

  componentWillMount = () => {
    console.log(this.props);
  }

  handleImageChange = (event, imageCategory) => {
    const image = event.target.files[0];

    let allowedImageTypes = ["image/png", "image/jpg", "image/jpeg"];
    if (allowedImageTypes.indexOf(image.type) === -1) {
      this.setState({
        errors: {
          ...this.state.errors,
          image: "Incorrect Image Type. Must be png or jpg.",
        },
      });
      console.error("Wrong image type");
      return;
    }

    // Pull image src out.
    var reader = new FileReader();
    reader.onload = (e) => {
      if (imageCategory === "thumbnail") {
        this.setState({
          thumbnailImageSrc: e.target.result,
        });
      } else if (imageCategory === "cover") {
        this.setState({
          coverImageSrc: e.target.result,
        });
      }
    };

    reader.readAsDataURL(image);

    if (imageCategory === 'thumbnail') {
      this.setState({
        thumbnailImage: image, 
      })
    } else if (imageCategory === 'cover') {
      this.setState({
        coverImage: image,
      })
    }
  };

  commitImageUploads = () => {
    this.commitThumbnailImageUpload();
    this.commitCoverImageUpload();
    this.props.toggle && this.props.toggle();
  }

  commitThumbnailImageUpload = () => {
    if (!this.state.thumbnailImage) return;
    let business = this.props.businessToUpdate || {};

    const formData = new FormData();
    formData.append(
      "image",
      this.state.thumbnailImage,
      this.state.thumbnailImage.name
    );
    this.props.uploadBusinessThumbnailImage(formData, business.businessId);

    this.setState({
      thumbnailImage: null,
      thumbnailImageSrc: null,
    })
  };

  commitCoverImageUpload = () => {
    if (!this.state.coverImage) return;
    let business = this.props.businessToUpdate || {};
    
    const formData = new FormData();
    formData.append("image", this.state.coverImage, this.state.coverImage.name);
    this.props.uploadBusinessCoverImage(formData, business.businessId);

    this.setState({
      coverImage: null,
      coverImageSrc: null,
    })
  };

  updateBusinessDetails = (values) => {
    window.analytics.track("EDIT_BUSINESS_DETAILS", {
      businessToUpdate: this.props.businessToUpdate,
      values: values,
    });

    if (!this.props.businessToUpdate) return;

    let toUpdate = {};

    // Go through each key. Save only the keys we've changed.
    Object.keys(this.props.businessToUpdate).forEach((key) => {
      if (values[key] && values[key] !== this.props.businessToUpdate[key]) {
        toUpdate[key] = values[key];
      }
    });

    // If no changes were made, just skip.
    if (Object.keys(toUpdate).length === 0) {
      this.props.toggle && this.props.toggle();
      return;
    }

    toUpdate.businessId = this.props.businessToUpdate.businessId;

    this.props.editBusinessDetails(toUpdate);
    this.props.toggle && this.props.toggle();
  };

  cancelUpdateBusiness = () => {
    this.props.toggle && this.props.toggle();
  };

  renderSubmitFooter = () => {
    return (
      <div className="update-info-footer">
        <SLButton
          trackingInfo={{name: "CANCEL_EDIT_BUSINESS"}}
          className="spendlocal-button-container spendlocal-cancel-button"
          type="reset"
          onClick={this.cancelUpdateBusiness}>Cancel</SLButton>
        <SLButton
          trackingInfo={{name: "SUBMIT_EDIT_BUSINESS"}}
          className="spendlocal-button-container spendlocal-accept-button"
          type="submit">Accept Changes</SLButton>
      </div>
    )
  }

  renderCausesForm = () => {
    let business = this.props.businessToUpdate || {};
    business.causes = business.causes || [];

    return (
      <div>
        <h4>Causes</h4>
        <p>Add new ways for patrons to support your business!</p>
        { 
          business.causes.map((cause, index) => <CauseTag 
            isAdmin={true}
            businessId={this.props.businessToUpdate.businessId}
            key={index} 
            featured={cause.causeId === business.featuredCauseId} 
            cause={cause}/>) 
        }
        <AddCauseTag onClick={this.props.addNewCause}/>
      </div>
    )
  }

  renderPublishForm = () => {
    let business = this.props.businessToUpdate || {};
 
    let publishInfoInitialValues = {
      readyToPublish: business.readyToPublish || false,
      shortURL: business.shortURL || "",
    };

    return (
      <div>
        <Formik
          initialValues={publishInfoInitialValues}
          validationSchema={publishBusinessInfoValidation}
          onSubmit={(values, actions) => {
            this.updateBusinessDetails(values);
            actions.setSubmitting(false);
          }}
          enableReinitialize>
          <Form className="business-info-form">
            <div className="update-info-row">
              <label className="update-info-label update-info-checkbox-label" htmlFor="readyToPublish">
                Ready To Publish
                <Field className="update-info-input" name="readyToPublish" render={({ field, form }) => {
                  return (
                      <input type="checkbox"
                        className="update-info-checkbox"
                        checked={field.value}
                        {...field}
                      />
                  ); 
                  }}
              />
              </label>
              <label className="update-info-sub-label" htmlFor="readyToPublish">
                Set your page as "Ready" and the SpendLocal team will work to approve it!
              </label>
            </div>

            <SLLink 
              trackingInfo={{name: "VIEW_BUSINESS_PAGE_FROM_PUBLISH"}}
              to={"/biz/" + business.businessId}>
                {"www.goSpendLocal.com/biz/" + business.businessId}
            </SLLink>

            {this.renderSubmitFooter()}
          </Form>
        </Formik>
      </div>
    )
  }

  renderContactForm = () => {
    let business = this.props.businessToUpdate || {};

    let contactInfoInitialValues = {
      phone: business.phone || "",
      website: business.website || "",
      address: business.address || "",
      zipCode: business.zipCode || "",
    };

    return (
      <div>
        <Formik
        initialValues={contactInfoInitialValues}
        validationSchema={contactBusinessInfoValidation}
        onSubmit={(values, actions) => {
          this.updateBusinessDetails(values);
          actions.setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form className="business-info-form">
            <div className="update-info-row">
              <label className="update-info-label" htmlFor="phone">
                Phone Number
              </label>
              <label className="update-info-sub-label" htmlFor="phone">
                A public number for your business
              </label>
              <Field className="update-info-input" type="text" name="phone" />
              <ErrorMessage
                className="update-info-error"
                name="phone"
                component="div"
              />
            </div>

            <div className="update-info-row">
              <label className="update-info-label" htmlFor="website">
                Website
              </label>
              <label className="update-info-sub-label" htmlFor="website">
                A website patrons can use to see your services.
              </label>
              <Field className="update-info-input" type="text" name="website" />
              <ErrorMessage
                className="update-info-error"
                name="website"
                component="div"
              />
            </div>

            <div className="update-info-row">
              <label className="update-info-label" htmlFor="address">
                Address
              </label>
              <label className="update-info-sub-label" htmlFor="address">
                The public address of your business.
              </label>
              <Field className="update-info-input" type="text" name="address" />
              <ErrorMessage
                className="update-info-error"
                name="address"
                component="div"
              />
            </div>

            <div className="update-info-row">
              <label className="update-info-label" htmlFor="zipCode">
                Zip Code
              </label>
              <label className="update-info-sub-label" htmlFor="zipCode">
                The 5-Digit Zip Code of the business.
              </label>
              <Field className="update-info-input" type="text" name="zipCode" />
              <ErrorMessage
                className="update-info-error"
                name="zipCode"
                component="div"
              />
            </div>

            {this.renderSubmitFooter()}
          </Form>  
        )}
        </Formik>
      </div>
    )
  }

  renderImageForm = () => {
    let business = this.props.businessToUpdate || {};
    return (
      <div>
        <div className="update-info-label-container">
          <label className="update-info-label" htmlFor="thumbnailImage">
            Thumbnail Image
          </label>
          <label className="update-info-sub-label" htmlFor="thumbnailImage">
            Shown on search pages. (1000x1000px)
          </label>
        </div>
        <span className="update-business-row-content">
          <span>
            <img
              alt="business thumbnail"
              className="update-business-thumbnail-image"
              src={
                this.state.thumbnailImageSrc
                  ? this.state.thumbnailImageSrc
                  : business.thumbnailImage
              }
            />
            <input
              name="thumbnailImage"
              hidden="hidden"
              type="file"
              id="update-business-thumbnail-input"
              onChange={(e) => this.handleImageChange(e, "thumbnail")}
            />
          </span>

            <SLButton
            className="update-business-image-button"
              onClick={() => {
                document
                  .getElementById("update-business-thumbnail-input")
                  .click();
              }}>
                <EditIcon /> Upload New Image
            </SLButton>
        </span>

        <div className="update-info-label-container">
          <label className="update-info-label" htmlFor="coverImage">
            Cover Image
          </label>
          <label className="update-info-sub-label" htmlFor="coverImage">
          Shown on the main business page. (1600x640px)
          </label>
        </div>
        <span className="update-business-row-content">
          <span>
            <img
              alt="business cover"
              className="update-business-cover-image"
              src={
                this.state.coverImageSrc
                  ? this.state.coverImageSrc
                  : business.coverImage
              }
            />
            <input
              name="coverImage"
              hidden="hidden"
              type="file"
              id="update-business-cover-input"
              onChange={(e) => this.handleImageChange(e, "cover")}
            />
          </span>

          <SLButton
            className="update-business-image-button"
              onClick={() => {
                document
                  .getElementById("update-business-cover-input")
                  .click();
              }}>
                <EditIcon /> Upload New Image
            </SLButton>
        </span>

        <div className="update-info-footer">
        <SLButton
          trackingInfo={{name: "CANCEL_EDIT_BUSINESS"}}
          className="spendlocal-button-container spendlocal-cancel-button"
          type="reset"
          onClick={this.cancelUpdateBusiness}>Cancel</SLButton>
        <SLButton
          trackingInfo={{name: "SUBMIT_EDIT_BUSINESS_IMAGE"}}
          onClick={this.commitImageUploads}
          className="spendlocal-button-container spendlocal-accept-button"
          type="submit">Accept Changes</SLButton>
            </div>
      </div>
    );
  };

  renderBasicInfoForm = () => {
    let business = this.props.businessToUpdate || {};

    let basicInfoInitialValues = {
      name: business.name || "",
      nickname: business.nickname || "",
      category: business.category || businessCategories[0],
      shortDescription: business.shortDescription || "",
      longDescription: business.longDescription || "",
      tags: business.tags || [],
    };

    return (
      <Formik
        initialValues={basicInfoInitialValues}
        validationSchema={basicBusinessInfoValidation}
        onSubmit={(values, actions) => {
          this.updateBusinessDetails(values);
          actions.setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form className="business-info-form">
            <div className="update-info-row">
              <label className="update-info-label" htmlFor="name">
                Business Name
              </label>
              <label className="update-info-sub-label" htmlFor="name">
                The official name of your business.
              </label>
              <Field className="update-info-input" type="text" name="name" />
              <ErrorMessage
                className="update-info-error"
                name="name"
                component="div"
              />
            </div>

            <div className="update-info-row">
              <label className="update-info-label" htmlFor="nickname">
                Business "nickname"
              </label>
              <label className="update-info-sub-label" htmlFor="nickname">
                What do locals call your business?
              </label>
              <Field
                className="update-info-input"
                type="text"
                name="nickname"
                placeholder="Local name."
              />
              <ErrorMessage
                className="update-info-error"
                name="nickname"
                component="div"
              />
            </div>

            <div className="update-info-row">
              <label className="update-info-label" htmlFor="category">
                Category
              </label>
              <label className="update-info-sub-label" htmlFor="category">
                What category does your business operate in?
              </label>
              <Field
                id="update-info-category-field"
                className="update-info-input update-info-category-input"
                as="select"
                name="category"
              >
                {businessCategories.map((category) => {
                  return (
                    <option key={category.id} value={category}>
                      {category}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                className="update-info-error"
                name="category"
                component="div"
              />
              {values.category === "Other" && (
                <Field
                  placeholder="Custom Category"
                  className="update-info-input update-info-category-input"
                  type="text"
                  name="secondaryCategory"
                />
              )}
              <ErrorMessage
                className="update-info-error"
                name="secondaryCategory"
                component="div"
              />
            </div>

            <div className="update-info-row">
              <label className="update-info-label" htmlFor="shortDescription">
                Short Description
              </label>
              <label
                className="update-info-sub-label"
                htmlFor="shortDescription"
              >
                A short note about what your business does.
              </label>
              <Field
                className="update-info-input"
                placeholder="We sell things!"
                type="text"
                name="shortDescription"
              />
              <ErrorMessage
                className="update-info-error"
                name="shortDescription"
                component="div"
              />
            </div>

            <div className="update-info-row">
              <label className="update-info-label" htmlFor="longDescription">
                Long Description
              </label>
              <label
                className="update-info-sub-label"
                htmlFor="longDescription"
              >
                Tell us who you are and what you're all about!
              </label>
              <Field
                className="update-info-input"
                type="text"
                name="longDescription"
                placeholder="Since 1923 we've been selling..."
              />
              <ErrorMessage
                className="update-info-error"
                name="longDescription"
                component="div"
              />
            </div>

            {/* <div className="update-info-row">
                <label className="update-info-label" htmlFor="tags">Tags</label>
                <label className="update-info-sub-label" htmlFor="tags">Give us some words that describe your business.</label>
                <Field className="update-info-input" type="text" name="tags" />
                <ErrorMessage className="update-info-error" name="tags" component="div" />
            </div> */}

            {this.renderSubmitFooter()}
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    let business = this.props.businessToUpdate || {};

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={"update-business-modal"}
      >
        <ModalHeader>
          Manage: {business.name}
        </ModalHeader>
        <ModalBody className="update-business-modal-body">
          <FormTabs tabs={Object.keys(formTypes)}>
            {this.renderBasicInfoForm()}
            {this.renderImageForm()}
            {this.renderContactForm()}
            {this.renderCausesForm()}
            {this.renderPublishForm()}
          </FormTabs>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(
  mapStateToProps, 
  { uploadBusinessThumbnailImage, uploadBusinessCoverImage, editBusinessDetails })
  (UpdateBusinessInfoModal);

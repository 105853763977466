import { 
  SET_USER, 
  SET_ERRORS, 
  CLEAR_ERRORS, 
  LOADING_UI, 
  SET_UNAUTHENTICATED,
  LOADING_USER,
  EDIT_BUSINESS,
  RESET_PASSWORD,
  STOP_LOADING_UI,
  LOADING_UI_ELEMENT,
  STOP_LOADING_UI_ELEMENT,
  STOP_LOADING_UI_ELEMENTS
} from '../types';

import axios from 'axios';

export const loginUser = (userData, history) => dispatch => {
    dispatch({type: LOADING_UI });
    dispatch({
      type: LOADING_UI_ELEMENT, 
      payload: 'login',
    });

    axios.post('/login', userData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData());
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: SET_ERRORS,
        payload: err.response,
      })

    })
}

export const signupUser = (newUserData, history) => dispatch => {
  dispatch({type: LOADING_UI });
  dispatch({
    type: LOADING_UI_ELEMENT, 
    payload: 'signup',
  })

  axios.post('/signup', newUserData)
  .then((res) => {
    setAuthorizationHeader(res.data.token);

    dispatch(getUserData());
    dispatch({type: CLEAR_ERRORS}); 

    history.push('/');
  })
  .catch((err) => {
    console.log(err);

    dispatch({
      type: SET_ERRORS,
      payload: err.response,
    })

  })
}

export const getUserData = () => (dispatch) => {
  dispatch({ type: LOADING_USER }); 
  
  dispatch({
    type: LOADING_UI_ELEMENT,
    payload: 'user',
  })
  
    axios.get('/user')
    .then(res => {
        dispatch({
            type: SET_USER,
            payload: res.data,
        });

        dispatch({
          type: STOP_LOADING_UI_ELEMENTS,
          payload: {
            login: false,
            signup: false,
            user: false,
          }
        });
    })
    .catch(err => {
        console.log("No Data", err);
    })
}

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('FBIdToken');
  delete axios.defaults.headers.common['Authorization'];
  dispatch({ type: SET_UNAUTHENTICATED });
}

export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: LOADING_USER });

  axios.post('/user', userDetails)
  .then(() => {
    dispatch(getUserData());
  })
  .catch((err) => console.log(err));
}

export const uploadUserImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post('/user/updateImage', formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

/**
 * Edit a business owned by the current user.
 * @param {Object} businessDetails Must include businessId of business to edit. 
 */
export const editBusinessDetails = (businessDetails) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
  .post(`/businesses/${businessDetails.businessId}/edit`, businessDetails)
  .then((res) => {
      dispatch({
          type: EDIT_BUSINESS,
          payload: res.data
      });
      dispatch(CLEAR_ERRORS);
  })
  .catch((err) => {
      dispatch({
          type: SET_ERRORS, 
          payload: err.data
      })
  });
}

export const uploadBusinessThumbnailImage = (formData, businessId) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post(`/businesses/${businessId}/updateThumbnailImage`, formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const uploadBusinessCoverImage = (formData, businessId) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post(`/businesses/${businessId}/updateCoverImage`, formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const resetPassword = (userInfo) => (dispatch) => {
  dispatch({type: LOADING_UI });
  axios
  .post('/user/resetPassword', userInfo)
  .then((res) => {
    dispatch({
      type: STOP_LOADING_UI,
      payload: res.data
    })
  })
  .catch(err => {
    console.log("err", err)
    dispatch({
      type: SET_ERRORS, 
      payload: err.data
    })
  })
}

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem('FBIdToken', FBIdToken);
  axios.defaults.headers.common['Authorization'] = FBIdToken;
}
import React from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import SpecialButton from '../components/SpecialButton';

import EditIcon from '@material-ui/icons/Edit';

// Redux
import { connect } from 'react-redux';
import { editUserDetails, uploadUserImage } from '../redux/actions/userActions';

// Formik
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Styles 
import '../styles/_manageprofilemodal.css';
import SLButton from '../components/SLButton';


const userProfileValidation = Yup.object().shape({
    bio: Yup.string()
      .max(200, 'Must be under 200 characters.'),
    zipCode: Yup.string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Must be a valid 5 or 9 digit zip code.")
  })

let initialValues = {
    bio: '',
    zipCode: '00000'
}

class ManageProfileModal extends React.Component {
    state = {
        errors: {},
        newImage: null,
        newImageSrc: null,
        zipCode: "",
    }

    handleProfileImageChange = (event) => {
        const image = event.target.files[0];

        let allowedImageTypes = ["image/png", "image/jpg", "image/jpeg"];
        if (allowedImageTypes.indexOf(image.type) === -1) {
            this.setState({
            errors: {
                ...this.state.errors,
                image: "Incorrect Image Type. Must be png or jpg."
            }
            })
            console.error("Wrong image type");
            return
        }

        // Pull image src out.
        var reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
            newImageSrc: e.target.result,
            })
        };
        reader.readAsDataURL(image);

        
        this.setState({
            newImage: image,
        })
    }

    commitImageUpload = () => {
        if (!this.state.newImage) return;
        const formData = new FormData();
        formData.append('image', this.state.newImage, this.state.newImage.name);
        this.props.uploadUserImage(formData);
    }

    acceptProfileChanges = () => {
        this.props.toggle && this.props.toggle();
        this.commitImageUpload();
    }
    
    cancelProfileChanges = () => {
        this.props.toggle && this.props.toggle();
        this.setState({
            newImage: null,
            newImageSrc: null,
        })
    }

    handleUserProfileUpdate = (userDetails) => {
        this.props.editUserDetails && this.props.editUserDetails(userDetails);
        this.commitImageUpload();
    } 

    render () {
        let bio = this.props.user.credentials.bio;
        let zipCode = this.props.user.credentials.zipCode;
        
        if (bio && initialValues.bio !== bio) {
            initialValues.bio = bio;
        } 

        if (zipCode && initialValues.zipCode !== zipCode) {
            initialValues.zipCode = zipCode;
        }

        return (
            <Modal 
                isOpen={this.props.isOpen} 
                toggle={this.props.toggleModal}
                className={"manage-profile-modal"}>
                <ModalHeader>Manage Profile Details</ModalHeader>
                <ModalBody className="manage-profile-modal-body">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={userProfileValidation}
                        onSubmit={(values, actions) => {
                            this.handleUserProfileUpdate(values);
                            actions.setSubmitting(false);
                        }}
                        enableReinitialize>
                            <Form className="manage-profile-form">
                                <div className="manage-profile-row">
                                <h4>Profile Image</h4>
                                <h6>Your public profile photo</h6>
                                <span className="manage-profile-row-content">
                                    <span>
                                        <img 
                                            alt="profile" 
                                            className="user-profile-image" 
                                            src={this.state.newImageSrc ? this.state.newImageSrc : this.props.user.credentials.imageUrl} />
                                            <input 
                                            hidden="hidden"
                                            type="file"
                                            id="profile-image-input"
                                            onChange={this.handleProfileImageChange} />
                                    </span>

                                    <SLButton
                                        className="manage-profile-image-button"
                                        onClick={() => {document.getElementById('profile-image-input').click()}}>
                                        <EditIcon/> Upload New Image
                                    </SLButton>
                                </span>

                                </div>
                                <div className="manage-profile-row">
                                    <h4>Zip Code</h4>
                                    <h6>We'll show you businesses around this area.</h6>
                                    <Field className="manage-profile-form-input manage-profile-zip-code-input" type="text" name="zipCode" />
                                    <ErrorMessage className="manage-profile-form-error" name="zipCode" component="div" />
                                </div>

                                <div className="manage-profile-row">
                                    <h4>Bio</h4>
                                    <h6>Tell other locals and businesses about you!</h6>
                                    <Field className="manage-profile-form-input" type="text" name="bio" />
                                    <ErrorMessage className="manage-profile-form-error" name="bio" component="div" />
                                </div>

                                <div className="manage-profile-footer">
                                    <SLButton
                                    trackingInfo={{name: "CANCEL_ADD_BUSINESS"}}
                                    className="spendlocal-button-container spendlocal-cancel-button"
                                    type="reset"
                                    onClick={this.cancelProfileChanges}>Cancel</SLButton>
                                    <SLButton
                                    onClick={this.acceptProfileChanges}
                                    trackingInfo={{name: "SUBMIT_ADD_BUSINESS"}}
                                    className="spendlocal-button-container spendlocal-accept-button"
                                    type="submit">Accept Changes</SLButton>
                                </div> 

                        </Form>
                    </Formik>
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
})

export default connect(
    mapStateToProps, 
    { editUserDetails, uploadUserImage }
)(ManageProfileModal);
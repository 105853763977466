import React from 'react';

// Redux
import { connect } from 'react-redux';
import { logoutUser } from '../redux/actions/userActions';
import '../styles/_nav.css';

import MenuIcon from '@material-ui/icons/Menu';

import logo from '../resources/GoSpendLocal_logo.svg';
import SLLink from '../components/SLLink';
import SLButton from '../components/SLButton';

let classNames = require("classnames");


class Nav extends React.Component {

  state = {
    menuOpen: false,
  }

  handleAddItem = () => {
    console.log("Add Item");
  }

  renderAuthenticatedActions = () => {
    return (
      <div className="nav-menu-action-container">
        {/* Log Out */}
        <SLLink
          to='/login'
          onClick={this.props.logoutUser}
          className="nav-bar-page-button nav-button">
          Log Out
        </SLLink>

        {/* Add Item */}
        {/* <div className="nav-bar-add-button-container">
          <button className="nav-bar-add-button"><img className="nav-bar-add-image" alt="a plus symbol"src={addImage}/></button>
        </div> */}
        {/* <div>
          <button onClick={this.handleAdd} className="nav-bar-add-button" id="nav-bar-add-button" />
        </div>  */}
        

        {/* See User Page */}
        <SLLink to='/user'>
          <img alt="circular profile" className="nav-menu-user-image" src={ this.props.user.credentials.imageUrl } />
        </SLLink>
      </div>
    )
  }

  renderUnauthenticatedActions = () => {
    return (
      <div className="nav-menu-action-container">
        {/* Log In */}
        <SLLink
          trackingInfo={{name: "VIEW_LOGIN_FROM_NAV"}}
          className="nav-bar-page-button nav-button"
          to='/login'>
            Log In
        </SLLink>

        {/* Sign Up */}
        <SLLink 
          trackingInfo={{name: "VIEW_SIGNUP_FROM_NAV"}}
          className="nav-bar-page-button nav-button nav-sign-up"
          to='/signup'>
            Sign Up
        </SLLink>


      </div>
    )
  }

  renderDesktop = () => {
    let { user } = this.props;
    let { authenticated } = user;

    return (
      <div className="nav-bar">

        <SLLink 
          className="nav-bar-home-button nav-button"
          to='/'>
            <img className="nav-bar-home-image" alt="a green and gray logo with the words Spend Local" src={logo} />
        </SLLink>

        <div className="nav-menu-links-container">
          {/* Businesses */}
          <SLLink 
          className="nav-bar-page-button nav-button"
          to='/businesses'>
            Businesses
          </SLLink>

          {/* Favorites */}
          {/* <Link to='/favorites'>
            <button className="nav-bar-page-button nav-button">
                Favorites
              </button>
          </Link> */}
        </div>

        {
          authenticated ?
          this.renderAuthenticatedActions() :
          this.renderUnauthenticatedActions()
        }
      </div>
    )
  }

  toggleMobileMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  renderMobileMenuItem = ({to, onClick, text}) => {
    onClick = onClick || (() => {});
    return (
      <SLLink 
      trackingInfo={{name: "VIEW_PAGE_FROM_NAV", info: {page: text}}}
      to={to}
      onClick={onClick} 
      className="nav-bar-mobile-menu-button">
        {text}
      </SLLink>
    )
  }

  renderMobile = () => {
    let { user } = this.props;
    let { authenticated } = user;

    return (
      <div className="nav-bar-mobile">
        <div className="nav-bar">
          <SLLink className="nav-bar-home-button nav-button" to='/'>
            <img className="nav-bar-home-image" alt="a green and gray logo with the words Spend Local" src={logo} />
          </SLLink>

          <SLButton
            className="nav-bar-menu-button non-border-button menu-icon"
            onClick={this.props.toggleNav}>
              <MenuIcon color="#68BB59"/> 
          </SLButton>
        </div> 
        <div className={classNames("nav-bar-mobile-items-container", {"open": this.props.navOpen})}>
          {!authenticated && this.props.navOpen && this.renderMobileMenuItem({
            text: 'Login',
            to: '/login'
          })}
          {!authenticated && this.props.navOpen && this.renderMobileMenuItem({
            text: 'Sign Up',
            to: '/signup'
          })}
          {this.props.navOpen && this.renderMobileMenuItem({
            text: 'Businesses',
            to: '/businesses',
          })}
          {/* {this.state.menuOpen && this.renderMobileMenuItem({
            text: 'Favorites',
            to: '/favorites',
          })} */}
          {authenticated && this.props.navOpen && this.renderMobileMenuItem({
            text: 'User Profile',
            to: '/user',
          })}
          {authenticated && this.props.navOpen && this.renderMobileMenuItem({
            text: 'Log Out',
            to: '/',
            onClick: this.props.logoutUser
          })}
        </div>
      </div>
 
    )
  }

  render () {
    if (this.props.isMobile || this.props.isTablet) {
      return this.renderMobile();
    } else {
      return this.renderDesktop();
    }
  }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
})

const mapActionsToProps = ({
    logoutUser
})

export default connect(mapStateToProps, mapActionsToProps)(Nav);

import {
    CREATE_BUSINESS,
    SET_BUSINESS,
    RESET_BUSINESS,
    LIKE_BUSINESS,
    UNLIKE_BUSINESS,
    SET_CAUSE,
    EDIT_CAUSE,
    DELETE_CAUSE,
    SET_FEATURED_BUSINESSES
} from '../types'

const initialState = {
    businesses: [],
    featuredBusinesses: [],
    business: {},
    cause: {},
    loading: false,
}

export default function(state = initialState, action) {
    switch(action.type) {

        case CREATE_BUSINESS:
            // window.analytics.track("DATA:" + CREATE_BUSINESS);
            return {
                ...state,
                businesses: [action.payload, ...state.businesses]
            };
        case SET_BUSINESS:
            // window.analytics.track("DATA:" + SET_BUSINESS);
            return {
                ...state,
                business: action.payload
            }
        case SET_FEATURED_BUSINESSES:
            // window.analytics.track("DATA:" + SET_FEATURED_BUSINESSES);
            return {
                ...state,
                featuredBusinesses: action.payload.featuredBusinesses,
            }
        case SET_CAUSE:
            // window.analytics.track("DATA:" + SET_CAUSE);
            return { 
                ...state,
                cause: action.payload
            }
        case EDIT_CAUSE:
            // window.analytics.track("DATA:" + EDIT_CAUSE);
            let newCause = action.payload;

            let replaceCause = (business, updatedCause) => {
                let causes = [...business.causes.filter(cause => cause.causeId !== updatedCause.id), updatedCause];
                business.causes = causes;
                return business;
            }

            return {
                ...state,
                businesses: state.businesses.map(business => replaceCause(business, newCause)),
                cause: action.payload.causeId === newCause.causeId ? action.payload : newCause,
            }
        case DELETE_CAUSE:
            // window.analytics.track("DATA:" + DELETE_CAUSE);
            let causeId = action.payload;

            return {
                ...state,
                businesses: state.businesses.map(business => {
                    business.causes = business.causes.filter(cause => !cause.causeId === causeId)
                    return business;
                }),
                cause: causeId === state.cause.causeId ? {} : state.cause,
            }
        case RESET_BUSINESS:
            // window.analytics.track("DATA:" + RESET_BUSINESS);
            return {
                ...state,
                business: {},
            }
        case LIKE_BUSINESS:
            const liked = true;
            // window.analytics.track("DATA:" + LIKE_BUSINESS);
        case UNLIKE_BUSINESS:
            // if (!liked) window.analytics.track("DATA:" + UNLIKE_BUSINESS);
            let index = state.businesses.findIndex(
                (business) => business.businessId === action.payload.businessId,
            )

            state.businesses[index] = action.payload;
            if (state.business.businessId === action.payload.businessId) {
                state.business = action.payload;
            }
        default:
            return state;
    }
}
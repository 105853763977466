import React from 'react';

import '../styles/_pageheader.css';

class PageHeader extends React.Component {
  render () {
    return (
      <div className="page-header">
        <div className="page-title">
          <h1 className="page-main-title">{this.props.mainTitle}</h1>
          <p className="page-sub-title">{this.props.subTitle}</p>
        </div>
        <div className="page-widget-container"> 
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PageHeader;
import React from 'react';

import '../styles/_localbusinessgoalbar.css';

function LocalBusinessGoalBar(props) {
    let cash, goal, supportAmount, supportNote;

    if (props.business && props.business.monthlyGoalCash && props.business.currentSupportCash) {
        cash = props.business.currentSupportCash;
        goal = props.business.monthlyGoalCash;
    } else {
        cash = 50;
        goal = 100;
        supportNote = "this business hasn't set a goal"
    }

    supportAmount = Math.floor(Math.min(cash/goal*100, 100));

    return (
      <div className="local-business-goal-bar">
        <div className="local-business-goal">
          <div 
            className="local-business-goal-achieved" 
            style={{"width": supportAmount + "%"}}/>
        </div>
        <div className="local-business-goal-text">
          {
            supportNote && <span className="local-business-goal-text-goal no-goal-set">{supportNote}</span>
          }
          {
            !supportNote && <span className="local-business-goal-text-achieved">${cash}</span> 
          }
          {
            !supportNote && <span className="local-business-goal-text-goal">of ${goal} goal</span>
          }
        </div>
      </div>
    )
}

export default LocalBusinessGoalBar
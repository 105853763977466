import React from 'react';
import './App.css';

// Components
import Nav from './util/Nav';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthRoute from './util/AuthRoute';
import { BrowserRouter as Router, Switch, Route, withRouter} from 'react-router-dom';

// Pages
import Businesses from './pages/Businesses';
import LocalBusiness from './pages/LocalBusiness';
import LocalBusinessChooseSupport from './pages/LocalBusinessChooseSupport';
import Organizations from './pages/Organizations';
import Favorites from './pages/Favorites';
import Community from './pages/Community';
import Login from './pages/Login';
import Home from './pages/Home';
import SignUp from './pages/Signup';
import UserProfile from './pages/UserProfile';
import ResetPassword from './pages/ResetPassword';
import PaymentComplete from './components/PaymentComplete';

// Libraries
import jwtDecode from 'jwt-decode';
import axios from 'axios';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser, getUserData } from './redux/actions/userActions';

// Styles
import './styles/bootstrap.css';
import TermsAndConditions from './pages/TermsAndConditions';
import Footer from './util/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import './styles/_spendlocal_main_style.css';

// axios.defaults.baseURL = "http://localhost:5000/spendlocal-d045b/us-central1/api";
axios.defaults.baseURL = "https://us-central1-spendlocal-d045b.cloudfunctions.net/api";

const token = localStorage.FBIdToken;

if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}

class App extends React.Component {

  state = {
    width: window.innerWidth,
    height: window.innerHeight,
    nextAuthSite: "businesses",
    navOpen: false,
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.analytics.page(this.props.location);
      window.scrollTo(0, 0); // Reset scroll.
      this.closeNav();
    }
  }

  setNextAuthSite = (site) => {
    this.setState({
      nextAuthSite: site,
    })
  }

  closeNav = () => {
    this.setState({
      navOpen: false,
    })
  }

  handleToggleNav = () => {
    this.setState({
      navOpen: !this.state.navOpen,
    });
  }

  render () {
    // if (!window.analytics) {
    //   window.analytics = {
    //     track: () => {}, // Add default tracking code here.
    //     page: () => {}, // Add default page code here
    //   };
    // }
    const isMobile = this.state.width < 600;
    const isTablet = this.state.width < 1100;

    const sizes = {isMobile, isTablet};

    return (
      <Provider store={store}>
          <div className="App">
            <ToastContainer />
            <Nav navOpen={this.state.navOpen} toggleNav={this.handleToggleNav} {...sizes}/>
            <Switch>
              <Route path="/" exact render={(props) => <Home {...props} {...sizes} />}/>
              <Route path="/login" exact render={(props) => <Login {...props} {...sizes} />}/>
              <Route path="/signup" exact render={(props) => <SignUp {...props} {...sizes} />}/>
              <Route path="/resetPassword" exact render={(props) => <ResetPassword {...props} {...sizes} />}/>
              <Route path="/businesses" exact render={(props) => <Businesses {...props} {...sizes} />}/>
              <Route path="/biz/:businessId" exact render={(props) => <LocalBusiness {...props} {...sizes} />}/>
              <Route path="/biz/:businessId/support/:causeId" exact component={LocalBusinessChooseSupport}/>
              <Route path="/organizations" exact component={Organizations}/>
              <Route path="/favorites" exact component={Favorites}/>
              <Route path="/community" exact component={Community}/>
              <Route path="/paymentComplete" exact component={PaymentComplete} />
              <Route path="/terms" exact component={TermsAndConditions}/>
              <Route path="/privacy" exact component={PrivacyPolicy}/>
              <AuthRoute 
                path="/user" 
                exact 
                component={UserProfile} />
            </Switch>
            <Footer />
          </div>
      </Provider> 
    );
  }
}

export default withRouter(App);
import React from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import SLButton from '../components/SLButton';

// Redux
import { connect } from 'react-redux';
import { createCause } from '../redux/actions/dataActions';

// Formik
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Styles 
import '../styles/_addnewcausemodal.css';
import '../styles/_addnewbusinessmodal.css';


const createCauseValidation
 = Yup.object().shape({
    title: Yup.string()
    .required("Needs a title.")  
    .min(10, 'Must be at least 10 characters.')
    .max(100, 'Must be under 200 characters.'),
    promise: Yup.string()
    .required("Needs a promise.")  
    .min(10, 'Must be at least 10 characters.')
    .max(200, 'Must be under 200 characters.'),
    promiseGoal: Yup.number()
    .required("Need to add a promise goal.")
    .min(1, 'Must be at least $1.'),
    benefit: Yup.string()
    .required("Must add a benefit.")
    .min(10, 'Must be at least 10 characters.')
    .max(100, 'Must be under 100 characters.'),
    benefitMinimum: Yup.number()
    .required("Must add a minimum amount to receive this benefit.")
    .min(1, "Must be at least $1."),
    benefitTerms: Yup.string()
    .required("Must add terms to this benefit")
    .min(10, "Must be at least 10 characters.")
    .max(200, "Cannot be more than 200 characters.")
  })

let initialValues = {
  title: '',
  promise: '', 
  promiseGoal: 0,
  benefit: '',
  benefitMinimum: 20,
  benefitTerms: 'Must show SpendLocal receipt when ordering. Limit 1 per customer. Valid until...',
}

class AddNewCauseModal extends React.Component {

  constructor (props) {
    super(props);
    this.pages = [
      {
        "name": "Promise",
        "render": this.renderPage1,
        "fields": ['promise', 'promiseGoal', 'title']
      },
      {
        "name": "Benfits",
        "render": this.renderPage2,
        "fields": ['benefit', 'benefitMinimum', 'terms']
      }
    ]

    this.state = {
      page: 0,
    }
  }

    handleAddNewCause = (causeDetails) => {
      let business = this.props.businessToUpdate || {}
      causeDetails.businessId = business.businessId;
      this.props.createCause(causeDetails);
      this.props.toggle();
    }

    proceed = ({validateForm, submitForm}) => {
      let page = this.pages[this.state.page];

      let valid = true;

    validateForm()
    .then(errors => {
      for (let field of page.fields) {
        if (errors[field]) {
          submitForm(); // Fake a form submission to surface errors.
          valid = false;
          break;
        }
      }

      if (valid) {
        this.setState({
          page: this.state.page + 1,
        })
      }
    })
  }

  goBack = () => {
    this.setState({
      page: this.state.page -1,
    })
  }

renderPage1 = () => {
    return (
      <div>
        <div className="add-new-business-row">
          <label 
            className="add-new-business-label add-new-cause-label" 
            htmlFor="title">Cause Title: Make it catchy!</label>
          <Field 
          placeholder="Free Meals to Medical Workers" 
          className="add-new-business-input" 
          type="text" 
          name="title" />
          <ErrorMessage 
          className="add-new-business-error" 
          name="title" 
          component="div" />
        </div>

        <div className="add-new-business-row">
          <label 
            className="add-new-business-label add-new-cause-label" 
            htmlFor="promiseGoal">Milestone: You'll fulfill your promise every time you get:</label>
          <Field 
            placeholder="20" 
            className="add-new-business-input" 
            type="text" name="promiseGoal" />
          <ErrorMessage 
            className="add-new-business-error" 
            name="promiseGoal" 
            component="div" />
        </div>        

        <div className="add-new-business-row">
          <label 
            className="add-new-business-label add-new-cause-label" 
            htmlFor="promise">Promise: What will you do with the funds?</label>
          <Field 
          placeholder="One free meal for a medical worker." 
          className="add-new-business-input" 
          type="text" 
          name="promise" />
          <ErrorMessage 
          className="add-new-business-error" 
          name="promise" 
          component="div" />
        </div>        
      </div>
    )
  }

  renderPage2 = ({validateForm, submitForm}) => {
    return (
      <div>
        <div className="add-new-business-row">
          <label className="add-new-business-label" htmlFor="benefitMinimum">Minimum Pledge: Minimum a person needs to provide to get this benefit.</label>
          <Field 
            placeholder="20" 
            className="add-new-business-input" 
            type="text" name="benefitMinimum" />
          <ErrorMessage className="add-new-business-error" name="benefitMinimum" component="div" />
        </div>

        <div className="add-new-business-row">
          <label className="add-new-business-label" htmlFor="benefit">Supporter Benefit: What will people get for supporting this cause?</label>
          <Field 
            placeholder="One free item with the purchase of a meal." 
            className="add-new-business-input" 
            type="text" name="benefit" />
          <ErrorMessage className="add-new-business-error" name="benefit" component="div" />
        </div>

        <div className="add-new-business-row">
          <label className="add-new-business-label" htmlFor="benefitTerms">Terms: What restrictions does this deal have?</label>
          <Field 
            placeholder="Must show SpendLocal receipt when ordering. Limit 1 per customer. Valid until..." 
            className="add-new-business-input" 
            type="text" name="benefitTerms" />
          <ErrorMessage className="add-new-business-error" name="benefitTerms" component="div" />
        </div>

        <div className="add-new-cause-footer">
          <SLButton
            trackingInfo={{name: "CANCEL_ADD_CAUSE"}}
            className="spendlocal-button-container spendlocal-cancel-button"
            type="reset"
            onClick={this.props.toggle}>Cancel</SLButton>
          <SLButton
            trackingInfo={{name: "SUBMIT_CREATE_CAUSE"}}
            className="spendlocal-button-container spendlocal-accept-button"
            type="submit"
            onClick={() => {validateForm().then(v => {console.log(v)})}}>Add Cause</SLButton>
        </div>
      </div>
    )
  }

  render () {
    return (
      <Modal 
        isOpen={this.props.isOpen} 
        toggle={this.props.toggleModal}
        className={"add-new-business-modal"}>
        <ModalHeader>Create a new cause<h6>Give patrons a new reason to support your business!</h6></ModalHeader>
        <ModalBody className="add-new-business-modal-body">
          <Formik
              initialValues={initialValues}
              validationSchema={createCauseValidation}
              onSubmit={(values, actions) => {
                  this.handleAddNewCause(values);
                  actions.setSubmitting(false);
              }}
              enableReinitialize>
                {({values, validateForm, submitForm}) => (
                  <Form className="add-new-business-modal-form">
                    <div className="add-new-cause-modal-preview">
                      Your supporters will see this:
                    </div>
                    <div className="add-new-cause-pledge-card">
                      <div className="add-new-cause-modal-title">
                        {values.title || "____"}
                      </div>
                      <div className="add-new-cause-modal-preview-complete">
                        {`For every $${values.promiseGoal || "____"} pledged, we'll ${values.promise || "____"}`}
                      </div>

                      <div className="add-new-cause-modal-preview-complete">
                        {`Support with at least $${values.benefitMinimum} and receive ${values.benefit || "____"}*`}
                      </div>
                      <div className="add-new-cause-modal-preview-terms">
                        {'*' + values.benefitTerms}
                      </div>
                    </div>

                    {this.renderPage1()}
                    {this.renderPage2({submitForm, validateForm})}
                  </Form>
                )}

          </Formik>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
    data: state.data,
})

export default connect(
    mapStateToProps, 
    { createCause }
)(AddNewCauseModal);
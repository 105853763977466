import { 
    CREATE_PAYMENT_INTENT
} from '../types';

const initialState = {
    clientSecret: "",
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CREATE_PAYMENT_INTENT:
            window.analytics.track("PAYMENT: " + CREATE_PAYMENT_INTENT);
            return {
                ...state,
                clientSecret: action.payload.clientSecret,
            }
        default: 
            return state;
    }
}